import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import Button from '~/app/shared/components/Button';
import Text from '~/app/shared/components/Text';

import ResourceLinkBox from './ResourceLinkBox';

const AddLinkButtonWrapper = styled.div`
  margin-top: 16px;
`;

const MessageWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ResourceLinkField = ({ formName, fields }) => {
  const [lastOpenedBox, setLastOpenedBox] = useState(fields.length - 1);

  const handleAddLink = () => {
    // NOTE: If you add or remove any field of the link resource, remember to update the forms
    fields.push({ name: '', link: '' });

    setLastOpenedBox(lastOpenedBox < 0 ? fields.length : lastOpenedBox + 1);
  };

  const handleOnRemoveBox = (index) => {
    setLastOpenedBox(-1);
    fields.remove(index);
  };

  return (
    <>
      {fields.length === 0 && (
        <MessageWrapper>
          <Text size="h4" medium color={colors.neutral500}>
            No links added yet. Click Add Link below to attach useful URLs to your content.
          </Text>
        </MessageWrapper>
      )}
      {fields.map((name, index) => (
        <ResourceLinkBox
          key={name}
          formName={formName}
          resource={name}
          index={index}
          handleOnRemove={() => handleOnRemoveBox(index)}
          fields={fields}
          lastOpenedBox={lastOpenedBox}
          setLastOpenedBox={setLastOpenedBox}
        />
      ))}
      <AddLinkButtonWrapper>
        <Button size="medium" type="button" icon="plus" onClick={handleAddLink} data-cy="addLink">
          Add Link
        </Button>
      </AddLinkButtonWrapper>
    </>
  );
};

ResourceLinkField.propTypes = {
  formName: PropTypes.string,
  fields: PropTypes.object,
};

export default ResourceLinkField;
