import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import actions from '~/app/entities/actions';
import { userSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import PrivacyField from '~/app/groups/components/PrivacyField';
import InputLabel from '~/app/inputs/components/InputLabel';
import SearchInput from '~/app/inputs/components/SearchInput';
import TextInput from '~/app/inputs/components/TextInput';
import colors from '~/services/colors';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { mapRoute } from '~/services/requests';
import AccessRestricted from '~/app/shared/components/AccessRestricted';
import Button from '~/app/shared/components/Button';
import Loading from '~/app/shared/components/Loading';
import MediaPoint from '~/app/shared/components/MediaPoint';
import FormPanel from '~/app/shared/components/OldForm/FormPanel';
import { STATUS_LOADING, STATUS_LOADING_MORE, STATUS_DONE } from '~/app/shared/constants';
import { useDebounce } from '~/app/shared/hooks';
import { EDIT_GROUP_PERMISSION } from '~/app/shared/permissions';
import { isEmpty, includes, join, get, map } from 'lodash-es';

import { useQuery } from '@tanstack/react-query';
import { queries } from '~/queries';

import GroupUserList from './GroupUserList';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  ${MediaPoint.DesktopSm} {
    margin-top: 50px;
  }
`;

const Header = styled.div`
  align-self: center;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;

  ${MediaPoint.Tablet} {
    width: 500px;
    flex-direction: row;
  }
  ${MediaPoint.DesktopSm} {
    width: 600px;
    flex-direction: row;
  }
`;

const HeaderTitle = styled.h2`
  color: ${colors.neutral400};
  justify-self: flex-start;
`;

const FormPanelContainer = styled.div`
  background-color: white;
  width: 300px;
  border-radius: 8px;
  align-self: center;
  margin-bottom: 30px;
  ${MediaPoint.Tablet} {
    width: 500px;
  }
  ${MediaPoint.DesktopSm} {
    width: 600px;
    margin-bottom: 60px;
  }
`;

const SimpleInfo = styled.div`
  color: ${colors.neutral500};
  font-size: 14px;
  margin-bottom: 8px;
`;

const GroupPrivacyOptions = styled.div`
  margin-bottom: 16px;
`;

const NameArea = styled.div`
  margin-bottom: 16px;
`;

const EditButtonContainer = styled.div`
  flex-basis: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  ${MediaPoint.DesktopSm} {
    flex-basis: 20%;
  }
`;

const Content = styled.div``;

const GroupDetail = () => {
  const { id } = useParams();

  const [regularMembers, setRegularMembers] = useState([]);
  const [owners, setOwners] = useState([]);
  const [memberSearchText, setMemberSearchText] = useState('');
  const [ownerSearchText, setOwnerSearchText] = useState('');

  const { trackActivity } = useMetrics();

  const handleOnMemberSearchTextChange = (value) => setMemberSearchText(value);
  const handleOnOwnerSearchTextChange = (value) => setOwnerSearchText(value);

  const debouncedMemberSearchText = useDebounce(memberSearchText, 500);
  const debouncedOwnerSearchText = useDebounce(ownerSearchText, 500);

  const { data: group, isLoading, isError } = useQuery(queries.groups.detail(id, 'detail'));

  const [
    fetchRegularMembers,
    { status: fetchRegularMembersStatus, nextPage: membersListMoreLink },
    fetchMembersNextPage,
  ] = useEntities(
    actions.userData.retrieveList,
    ({ data, status }) => {
      if (status === STATUS_DONE) {
        setRegularMembers(data);
      }
    },
    {
      schema: [userSchema],
      loadMoreAction: actions.userData.retrieveListLoadMore,
    }
  );

  const [
    fetchOwners,
    { status: fetchOwnersStatus, nextPage: ownersListMoreLink },
    fetchOwnersNextPage,
  ] = useEntities(
    actions.userData.retrieveList,
    ({ data, status }) => {
      if (status === STATUS_DONE) {
        setOwners(data);
      }
    },
    {
      schema: [userSchema],
      loadMoreAction: actions.userData.retrieveListLoadMore,
    }
  );

  useEffect(() => {
    if (!group) return;

    if (isEmpty(group.regular_members)) return;

    fetchRegularMembers({
      group: group.id,
      page_size: 20,
      q: debouncedMemberSearchText,
      view_mode: 'with_location',
    });
  }, [group, debouncedMemberSearchText]);

  useEffect(() => {
    if (!group) return;

    if (isEmpty(group.owners_ids)) return;

    const ownersIDs = join(group.owners_ids, ',');
    fetchOwners({
      id__in: ownersIDs,
      page_size: 20,
      q: debouncedOwnerSearchText,
      view_mode: 'with_location',
    });
  }, [group, debouncedOwnerSearchText]);

  useEffect(() => {
    if (!group) return;
    trackActivity(METRICS_ACTIVITIES.VIEW_GROUP, { group_id: group.id });
  }, [group]);

  if (isLoading) return <Loading />;

  if (isError) {
    return (
      <Container>
        <Header>
          <AccessRestricted />
        </Header>
      </Container>
    );
  }

  const { permissions } = group;
  const queryParams = `?origin=${mapRoute('groupDetails', { id: `${group.id}` })}`;
  const contentItems = get(group, 'content_items', []);

  return (
    <Container>
      <Header>
        <HeaderTitle>View {group.name}</HeaderTitle>
        {includes(permissions, EDIT_GROUP_PERMISSION) && (
          <EditButtonContainer>
            <Button
              route={`${mapRoute('groupEdit', {
                id: `${group.id}`,
              })}${queryParams}`}
              type="button"
              size="small"
              data-cy="edit-group-button"
            >
              Edit Group
            </Button>
          </EditButtonContainer>
        )}
      </Header>
      <FormPanelContainer>
        <FormPanel title="Group Details">
          <Content>
            <NameArea>
              <TextInput label="Name" value={group.name} disabled />
            </NameArea>
            <GroupPrivacyOptions>
              <InputLabel>Group Privacy</InputLabel>
              <PrivacyField disabled input={{ value: group.is_private }} label="Private" />
            </GroupPrivacyOptions>
            {group.source === 'integration' && <SimpleInfo>Created by Integration</SimpleInfo>}
            <SimpleInfo>Last Edited: {moment(group.modified).format('MMM DD, YYYY')}</SimpleInfo>
            <SimpleInfo>Active since: {moment(group.created).format('MMM DD, YYYY')}</SimpleInfo>
          </Content>
        </FormPanel>
        <FormPanel
          title={`Content (${group.content_items_count || 0})`}
          padding={0}
          dataCy="content-panel"
        >
          {map(contentItems, (contentItem) => (
            <div key={contentItem.public_id_and_slug}>
              <a href={contentItem.url} target="_blank" rel="noreferrer">
                {contentItem.name}
              </a>
              <br />
            </div>
          ))}
        </FormPanel>
        <FormPanel title={`Owners (${group.owners_count || 0})`} padding={0} dataCy="owners-panel">
          <SearchInput
            placeholder="Search group owners"
            value={ownerSearchText}
            onChange={handleOnOwnerSearchTextChange}
          />
          {isEmpty(owners) && fetchOwnersStatus === STATUS_LOADING ? (
            <Loading />
          ) : (
            <GroupUserList
              users={owners}
              fetchNextPage={fetchOwnersNextPage}
              isLoadingMore={fetchOwnersStatus === STATUS_LOADING_MORE}
              loadMoreLink={ownersListMoreLink}
            />
          )}
        </FormPanel>
        <FormPanel
          title={`Members (${group.members_count || 0})`}
          padding={0}
          dataCy="members-panel"
        >
          <SearchInput
            placeholder="Search group members"
            value={memberSearchText}
            onChange={handleOnMemberSearchTextChange}
          />
          {isEmpty(regularMembers) && fetchRegularMembersStatus === STATUS_LOADING ? (
            <Loading />
          ) : (
            <GroupUserList
              users={regularMembers}
              fetchNextPage={fetchMembersNextPage}
              isLoadingMore={fetchRegularMembersStatus === STATUS_LOADING_MORE}
              loadMoreLink={membersListMoreLink}
            />
          )}
        </FormPanel>
      </FormPanelContainer>
    </Container>
  );
};

export default GroupDetail;
