import queryString from 'query-string';
import { useEffect, useRef, useState, Suspense } from 'react';
import { useLocation } from 'react-router-dom';

import { rqlToInput } from '~/app/backoffice/components/Dashboard/Filters/utils';
import CatalogDiscoverSection from '~/app/catalog/components/CatalogDiscoverSection';
import ViewAllContent from '~/app/catalog/components/ViewAllContent';
import { SettingConfiguredWidgetList } from '~/common/components/SettingConfiguredWidgetList';
import CardListWrapper from '~/app/content-items/components/CardListWrapper';
import { useFetchDataListPaginated } from '~/app/data-fetching/hooks';
import { EVENT_VIEW_MODES } from '~/app/event/constants';
import EventViewDensity from '~/app/events/components/EventViewDensity';
import { useEventsFiltersDefinition, useEventsPillsDefinition } from '~/app/events/hooks';
import {
  convertPeriodFilterToRQLFactory,
  joinMultipleDatesToRangeFactory,
  translatorFactory,
} from '~/app/feature-parity/utils';
import RQLFilterBar from '~/app/filters/components/RQLFilterBar';
import { useRQLFilters } from '~/app/filters/hooks';
import { excludeFieldFromRQLExpression } from '~/app/filters/utils';
import FilterPills from '~/app/navigation/components/FilterPills';
import { queries } from '~/queries';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { lazyWithRetry } from '~/services/utils';
import CardList from '~/app/shared-cards/components/CardList';
import { OptimalContentItemCard } from '~/app/shared/components/Card';
import Loading from '~/app/shared/components/Loading';
import PageTitle from '~/app/shared/components/PageTitle/PageTitle';
import {
  useCurrentUser,
  useStickyFilters,
  useOrderingLabel,
  useChannelToggle,
} from '~/app/shared/hooks';
import { buildFlexibleTagsFieldNameList } from '~/app/topics/services';
import {
  get,
  includes,
  map,
  filter,
  forEach,
  isArray,
  isEmpty,
  isNil,
  has,
  reduce,
  zip,
} from 'lodash-es';
import { grey } from '@mui/material/colors';
import { Typography } from '~/common/components/Typography';
import { styled } from '@mui/material';
import { ErrorBoundary } from '~/app/shared';

const LazyCalendar = lazyWithRetry(
  () => import(/* webpackChunkName: "Calendar" */ '~/app/event-list/components/Calendar')
);

const NoEventsFoundMessage = styled('div')`
  margin: 20px 0;
  text-align: center;
  font-size: 25px;
`;

const CalendarContainer = styled('div')`
  padding: 20px;
  background-color: white;
  height: 750px;
`;

const PAGE_SIZE = 24;
const CALENDAR_PAGE_SIZE = 30;

const Events = () => {
  const { trackActivity } = useMetrics();
  const location = useLocation();
  const currentUser = useCurrentUser();
  const search = useRef(isEmpty(location.search) ? '' : location.search.slice(1)); // remove the '?' symbol
  const defaultTabsQueryStrings = useRef(
    map(
      filter(get(currentUser, 'tabs_configuration'), (tab) => get(tab, 'module') === 'events'),
      'querystring'
    )
  );
  const [isStickyFiltersLoaded, setIsStickyFiltersLoaded] = useState(
    // Load the sticky filters only if the user is accessing the page with an empty query string or
    // one of the custom tab configurations which has a query string defined.
    !isEmpty(search.current) && !includes(defaultTabsQueryStrings.current, search.current) // set as true if the user is accessing the page with a search defined
  );

  // Filters
  const translator = translatorFactory({
    translationMap: {
      category: 'main_topics',
      tag: 'topics',
      event_type: 'event_types',
    },
    defaultOrdering: 'start_time',
    processingMap: {
      period: convertPeriodFilterToRQLFactory('start_time'),
      starts_after: (value) => ({ start_time: { $gt: value as string } }),
      starts_before: (value) => ({ start_time: { $lt: value as string } }),
    },
    postProcessing: joinMultipleDatesToRangeFactory('start_time'),
  });
  const {
    filters,
    ordering,
    updateFilter,
    removeValue,
    setOrdering,
    resetFilters,
    rqlExpression,
    processExpression,
  } = useRQLFilters({
    initialFiltersState: {
      start_time: { $gt: '-PT0H' },
      status: { $eq: 'published' },
      view_density: { $eq: 'cozy' },
    },
    initialOrdering: 'start_time',
    translator,
  });
  if (!isNil(filters) && !get(filters, 'status', false)) {
    filters.status = { $eq: 'published' };
  }
  // Page configuration
  const viewDensity = get(filters, 'view_density.$eq', 'cozy') as string;

  // Data fetching
  const pageSize = viewDensity === 'cozy' ? PAGE_SIZE : CALENDAR_PAGE_SIZE;
  const viewMode = viewDensity === 'cozy' ? EVENT_VIEW_MODES.listing : EVENT_VIEW_MODES.calendar;
  const normalizedExpression = excludeFieldFromRQLExpression(rqlExpression, 'view_density');
  const searchRQLExpression = `${normalizedExpression}&page_size=${pageSize}&view_mode=${viewMode}`;
  const {
    data: events,
    fetchMore: loadMoreEvents,
    status: fetchStatus,
    count,
    hasNextPage,
    nextPage,
    isLoading,
  } = useFetchDataListPaginated({
    ...queries.events.list(searchRQLExpression),
    enabled: !isEmpty(rqlExpression) && isStickyFiltersLoaded,
  });

  // Sticky filters
  const [stickyFilters, updateStickyFilters] = useStickyFilters('events_page');
  const stickyRqlExpression = has(stickyFilters, 'rqlExpression')
    ? get(stickyFilters, 'rqlExpression', '')
    : stickyFilters // check if the sticky filter are in the old format
      ? queryString.stringify(stickyFilters)
      : '';

  const includeFilterChannels = useChannelToggle();

  // Filters definition
  const { filters: barFilters, moreFilters } = useEventsFiltersDefinition({
    filters,
    ordering,
    updateFilter,
    setOrdering,
    fixedFilters: ['My Events'],
    includeFilterChannels,
  });

  // Pills definition
  const { pills } = useEventsPillsDefinition({ filters });

  // Creating reference to mutable values to avoid re-renders
  const cachedTrackActivity = useRef(trackActivity);
  const cachedUpdateStickyFilters = useRef(updateStickyFilters);

  useEffect(() => {
    cachedTrackActivity.current = trackActivity;
    cachedUpdateStickyFilters.current = updateStickyFilters;
  });

  // Track activity
  useEffect(() => {
    if (rqlExpression) {
      cachedTrackActivity.current(METRICS_ACTIVITIES.EVENTS_VIEW, { filters: rqlExpression });
    }
  }, [rqlExpression]);

  // Load sticky filters
  useEffect(() => {
    if (
      !isStickyFiltersLoaded &&
      !isEmpty(stickyRqlExpression) &&
      rqlExpression &&
      (isEmpty(search.current) || includes(defaultTabsQueryStrings.current, search.current))
    ) {
      // Ensure it loads once and the initial URL was processed
      setIsStickyFiltersLoaded(true);
      processExpression(stickyRqlExpression);
    }
    if (isEmpty(stickyRqlExpression)) {
      setIsStickyFiltersLoaded(true);
    }
  }, [stickyRqlExpression, isStickyFiltersLoaded, processExpression, rqlExpression]);

  useEffect(() => {
    if (rqlExpression && isStickyFiltersLoaded) {
      cachedUpdateStickyFilters.current({ rqlExpression });
    }
  }, [rqlExpression, isStickyFiltersLoaded]);

  // Do not show the widget list if the user is filtering the events list
  const showWidgetList =
    rqlExpression ===
    `(gt(start_time,-PT0H))&(eq(status,published))&(eq(view_density,cozy))&ordering(${ordering})`;

  const displayCatalogDiscoverSection = !isLoading && count < 5;
  const orderingLabel = useOrderingLabel(ordering);
  const onRemove = (item) => {
    const name = get(item, 'filterName', '');
    const value = get(item, 'value', '');
    if (isArray(name) && isArray(value)) {
      forEach(zip(name, value), ([filterName, filterValue]) => {
        removeValue(filterName, filterValue);
      });
    } else {
      removeValue(name, value);
    }
  };
  return (
    <>
      <PageTitle title="Events" />
      <CardListWrapper>
        <Typography variant="h4" sx={{ color: grey['900'] }} fontWeight={500}>
          Events
        </Typography>
        <RQLFilterBar filters={barFilters} moreFilters={moreFilters} onClearAll={resetFilters}>
          <EventViewDensity
            key="view-density"
            value={viewDensity}
            onChange={(newViewDensity) => updateFilter({ view_density: { $eq: newViewDensity } })}
          />
        </RQLFilterBar>
        <FilterPills pills={pills} onRemove={onRemove} />
        {showWidgetList && viewDensity === 'cozy' && (
          <SettingConfiguredWidgetList orderSetting="widgets_order.widget_order_events" />
        )}
        {isLoading && viewDensity === 'cozy' && <Loading />}

        {!isLoading && count === 0 && viewDensity === 'cozy' && (
          <NoEventsFoundMessage>No events found for the selected filters.</NoEventsFoundMessage>
        )}
        {viewDensity === 'cozy' && (
          <ViewAllContent
            fetchStatus={fetchStatus}
            fetchNextPage={loadMoreEvents}
            hasNextPage={hasNextPage}
            totalResults={count}
            tooltipMessage={orderingLabel}
          >
            <CardList
              items={events}
              renderItem={(item) => (
                <OptimalContentItemCard
                  key={`${item.content_type}_${item.id}`}
                  contentItem={item}
                />
              )}
            />
          </ViewAllContent>
        )}
        {viewDensity === 'calendar' && (
          <CalendarContainer>
            <ErrorBoundary>
              <Suspense fallback={<Loading />}>
                <LazyCalendar
                  onFilterChange={({ starts_after, starts_before }) =>
                    updateFilter({
                      start_time: {
                        $range: { min: get(starts_after, '0'), max: get(starts_before, '0') },
                      },
                    })
                  }
                  eventList={events}
                  eventListStatus={fetchStatus}
                  eventListMoreLink={nextPage}
                  fetchNextPage={loadMoreEvents}
                />
              </Suspense>
            </ErrorBoundary>
          </CalendarContainer>
        )}
        {displayCatalogDiscoverSection && (
          <CatalogDiscoverSection
            // It is necessary to convert back the RQL filters to QueryString because
            // this is the only format that the discovery component can handle.
            selectedFilters={{
              q: get(filters, 'q.$eq'),
              main_topics: rqlToInput(get(filters, 'category')),
              topics: rqlToInput(get(filters, 'tag')),
              ...reduce(
                buildFlexibleTagsFieldNameList(currentUser),
                (acc, filterName) => ({
                  ...acc,
                  [filterName]: rqlToInput(get(filters, filterName)),
                }),
                {}
              ),
            }}
          />
        )}
      </CardListWrapper>
    </>
  );
};

export default Events;
