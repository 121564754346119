import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import Text from '~/app/shared/components/Text';

import DeleteButton from './DeleteButton';
import FormPanel from './FormPanel';

const DeleteTextContainer = styled.div`
  margin: 16px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const DeletePanel = ({ title, deleteText, buttonText, handleDelete, isEditing }) => {
  if (!isEditing) return null;

  return (
    <FormPanel title={title} openDefault={false} dataCy="delete-panel">
      <DeleteTextContainer>
        <Text block size="h5" color={colors.neutral600}>
          {deleteText}
        </Text>
      </DeleteTextContainer>
      <ButtonContainer>
        <DeleteButton data-cy="delete-button" onClick={handleDelete}>
          {buttonText || 'Delete'}
        </DeleteButton>
      </ButtonContainer>
    </FormPanel>
  );
};

DeletePanel.propTypes = {
  title: PropTypes.string,
  deleteText: PropTypes.string,
  buttonText: PropTypes.string,
  handleDelete: PropTypes.func,
  isEditing: PropTypes.bool,
};

export default DeletePanel;
