import queryString from 'query-string';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

import { toast } from '~/app/notifications/components/NotificationCenter';
import colors from '~/services/colors';
import { useEntityMetrics } from '~/services/metrics';
import MediaPoint from '~/app/shared/components/MediaPoint';
import { useCurrentUser } from '~/app/shared/hooks';

import { get } from 'lodash-es';
import { useCreateGroup } from '~/features/groups/api/mutations';

import GroupCreateForm from './GroupCreateForm';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 30px;
  ${MediaPoint.DesktopSm} {
    margin-top: 50px;
  }
`;

const Header = styled.div`
  align-self: center;
  width: 300px;
  display: flex;
  margin-bottom: 16px;

  ${MediaPoint.Tablet} {
    width: 500px;
  }
  ${MediaPoint.DesktopSm} {
    width: 600px;
  }
`;

const HeaderTitle = styled.h2`
  color: ${colors.neutral400};
  justify-self: flex-start;
`;

const GroupCreate = () => {
  const { trackEntityActivity } = useEntityMetrics();

  const currentUser = useCurrentUser();
  const history = useHistory();
  const location = useLocation();

  const createGroupMutation = useCreateGroup();

  const onSubmitHandler = async (values) => {
    const response = await createGroupMutation.mutateAsync(values);
    return response;
  };

  const onSubmitSuccessHandler = (result) => {
    trackEntityActivity({
      id: result.id,
      isEdit: false,
      entityType: 'group',
    });

    const { home_page_url_path: homePath } = currentUser;
    toast.success(
      'New group created',
      `You created group ${result.name} as a ${
        result.is_private ? 'private' : 'public'
      } group with ${result.members_count} ${
        result.members_count === 1 ? 'member' : 'members'
      }. It will appear on your list of groups whenever you are creating an event, inviting people to events or creating mentorship sessions.`
    );
    const args = queryString.parse(location.search);
    if (!get(args, 'origin')) {
      history.push(homePath);
    }
    history.push(args.origin);
  };

  return (
    <Container>
      <Header>
        <HeaderTitle>New Group</HeaderTitle>
      </Header>
      <GroupCreateForm
        onSubmitSuccessHandler={onSubmitSuccessHandler}
        onSubmitHandler={onSubmitHandler}
      />
    </Container>
  );
};
export default GroupCreate;
