import * as React from 'react';
import { Try } from '@mui/icons-material';
import { Box, Button, Rating } from '@mui/material';
import { get } from 'lodash-es';
import ContentRating from '~/app/stand-alone-shared/components/ContentRating';
import { useAssignmentActionsCtx } from '~/features/contentitems/contexts/AssignmentActionsCtx';

import { ASSIGNMENT_STATES } from '~/app/assignments/constants';
import { CONTENT_TYPES } from '~/app/catalog/constants';
import { Assignment, ContentItem } from '~/app/shared-content-item/interfaces';
import { useTrackContentConsumptionCtx } from '../contexts/TrackContentConsumptionCtx';
import { TrackItemAssignmentActionsProvider } from './TrackItemAssignmentActionsProvider';
import { LinkedContent } from '~/app/linkedcontent/interfaces';
import CodelabPrimaryActionButton from '~/scenes/CodelabDetail/PrimaryActionButton';
import CoursePrimaryActionButton from '~/scenes/CourseDetail/PrimaryActionButton';
import EventTypePrimaryActionButton from '~/scenes/EventTypeDetail/PrimaryActionButton';
import LinkedContentPrimaryActionButton from '~/scenes/LinkedContentDetail/PrimaryActionButton';
import VideoPrimaryActionButton from '~/scenes/VideoDetail/PrimaryActionButton';
import DefaultPrimaryActionButton from '~/app/shared-content-item/components/PrimaryActionButton';
import { TrackPrimaryActionButton } from './TrackPrimaryActionButton';
import { TrackContentAdvanceAction } from './TrackContentAdvanceAction';
import EventActionButtons from '~/scenes/EventDetail/EventActionButtons';

type TrackItemPrimaryActionSwitcherProps = {
  contentItem: ContentItem;
};

export function TrackItemPrimaryActionSwitcher(props: TrackItemPrimaryActionSwitcherProps) {
  const { contentItem } = props;

  const { currentTrackItemContentType: contentType } = useTrackContentConsumptionCtx();

  return (
    <>
      {contentType === CONTENT_TYPES.eventtype && (
        <EventTypePrimaryActionButton content={contentItem} />
      )}
      {contentType === CONTENT_TYPES.event && <EventActionButtons content={contentItem} />}
      {contentType === CONTENT_TYPES.article && (
        <DefaultPrimaryActionButton content={contentItem} />
      )}
      {contentType === CONTENT_TYPES.linkedcontent && (
        <LinkedContentPrimaryActionButton content={contentItem as LinkedContent} />
      )}
      {contentType === CONTENT_TYPES.video && <VideoPrimaryActionButton content={contentItem} />}
      {contentType === CONTENT_TYPES.codelab && (
        <CodelabPrimaryActionButton content={contentItem} />
      )}
      {contentType === CONTENT_TYPES.course && <CoursePrimaryActionButton content={contentItem} />}
      {contentType === CONTENT_TYPES.task && <DefaultPrimaryActionButton content={contentItem} />}
      {contentType === CONTENT_TYPES.assessment && (
        <TrackPrimaryActionButton content={contentItem} />
      )}
      {contentType === CONTENT_TYPES.track && <TrackPrimaryActionButton content={contentItem} />}
    </>
  );
}

type TrackItemFeedbackActionProps = {
  contentItem: ContentItem;
  assignment: Assignment;
};

function TrackItemFeedbackAction(props: TrackItemFeedbackActionProps) {
  const { contentItem, assignment } = props;

  const [isRatingContent, setRatingContent] = React.useState(false);

  const { rate, isLoading } = useAssignmentActionsCtx();

  const handleRateContent = (rateBody: any) => {
    rate(rateBody);
  };

  const currentRating = get(assignment, 'feedback_rating') || get(assignment, 'rating.rating', 0);

  return (
    <>
      {currentRating <= 0 && (
        <Button
          variant="text"
          startIcon={<Try />}
          onClick={() => setRatingContent(true)}
          disabled={isLoading}
        >
          Feedback
        </Button>
      )}

      {currentRating > 0 && (
        <Box display="flex" alignItems="center" gap="4px">
          <Rating value={currentRating} readOnly />

          <Button
            variant="text"
            size="small"
            onClick={() => setRatingContent(true)}
            disabled={isLoading}
            startIcon={<Try />}
          >
            Edit
          </Button>
        </Box>
      )}

      {isRatingContent && (
        <ContentRating
          content={contentItem}
          onSubmitFunc={handleRateContent}
          handleClose={() => setRatingContent(false)}
        />
      )}
    </>
  );
}

type TrackItemConsumptionActionsProps = {
  contentItem: ContentItem;
  assignment: Assignment;
};

export function TrackItemConsumptionActions(props: TrackItemConsumptionActionsProps) {
  const { contentItem, assignment } = props;

  const isCurrentItemCompleted = assignment.state === ASSIGNMENT_STATES.completed;

  return (
    <TrackItemAssignmentActionsProvider contentItem={contentItem} assignment={assignment}>
      <Box display="flex" alignItems="center" gap={2}>
        {isCurrentItemCompleted && (
          <TrackItemFeedbackAction contentItem={contentItem} assignment={assignment} />
        )}

        <TrackItemPrimaryActionSwitcher contentItem={contentItem} />

        <TrackContentAdvanceAction contentItem={contentItem} />
      </Box>
    </TrackItemAssignmentActionsProvider>
  );
}
