import moment from 'moment';
import { denormalize } from 'normalizr';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { Field, Fields, reduxForm, formValueSelector } from 'redux-form';
import styled from 'styled-components';

import actions from '~/app/entities/actions';
import { userSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import GroupOwnerListFields from '~/app/groups/components/GroupOwnerListFields';
import GroupUserListFields from '~/app/groups/components/GroupUserListFields';
import PrivacyField from '~/app/groups/components/PrivacyField';
import TextField from '~/app/inputs/components/TextField';
import { toast } from '~/app/notifications/components/NotificationCenter';
import colors from '~/services/colors';
import confirmAlert from '~/services/confirm-alert';
import { handleFailureWithConfirmation, submitWithConfirmation } from '~/services/forms';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { getErrorMessageFromResponse } from '~/services/requests';
import Button from '~/app/shared/components/Button';
import MediaPoint from '~/app/shared/components/MediaPoint';
import Form from '~/app/shared/components/OldForm';
import { STATUS_DONE, STATUS_LOADING_MORE } from '~/app/shared/constants';
import * as formValidations from '~/app/shared/formValidations';
import { useDebounce, useFormPreventTransition } from '~/app/shared/hooks';
import * as permissionConstants from '~/app/shared/permissions';

import { includes, get, isEmpty, map, join, size } from 'lodash-es';
import { useDeleteGroup } from '~/features/groups/api/mutations';
import { to } from '~/common/helpers';
import { mapRoute } from '~/services/requests';

const FormPanelContainer = styled.div`
  width: 300px;
  border-radius: 8px;
  align-self: center;
  ${MediaPoint.Tablet} {
    width: 500px;
  }
  ${MediaPoint.DesktopSm} {
    width: 600px;
  }
`;

const SimpleInfo = styled.div`
  color: ${colors.neutral500};
  font-size: 14px;
  margin-bottom: 8px;
`;

const GroupPrivacyOptions = styled.div`
  margin-bottom: 16px;
`;

const NameArea = styled.div`
  margin-bottom: 16px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;

  gap: 16px;
`;

const RadioButtonArea = styled.div`
  margin-top: 10px;
`;

const RemovalDialogTextBlock = styled.div`
  margin-bottom: 15px;
`;

const fieldRequired = formValidations.required();

const GroupEditForm = ({
  initialValues: group,
  redirectionRoute,
  currentUser,
  handleSubmit,
  pristine,
  submitting,
  form,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { trackActivity } = useMetrics();

  useFormPreventTransition(form);
  const deleteGroupMutation = useDeleteGroup();

  const [regularMembers, setRegularMembers] = useState([]);
  const [owners, setOwners] = useState([]);
  const [memberSearchText, setMemberSearchText] = useState('');
  const [ownerSearchText, setOwnerSearchText] = useState('');

  const handleOnMemberSearchTextChange = (value) => setMemberSearchText(value);
  const handleOnOwnerSearchTextChange = (value) => setOwnerSearchText(value);

  const debouncedMemberSearchText = useDebounce(memberSearchText, 500);
  const debouncedOwnerSearchText = useDebounce(ownerSearchText, 500);

  const [
    fetchRegularMembers,
    { status: fetchRegularMembersStatus, nextPage: membersListMoreLink },
    fetchMembersNextPage,
  ] = useEntities(
    actions.userData.retrieveList,
    ({ data, status }) => {
      if (status === STATUS_DONE) {
        setRegularMembers(data);
      }
    },
    {
      schema: [userSchema],
      loadMoreAction: actions.userData.retrieveListLoadMore,
    }
  );

  const [
    fetchOwners,
    { status: fetchOwnersStatus, nextPage: ownersListMoreLink },
    fetchOwnersNextPage,
  ] = useEntities(
    actions.userData.retrieveList,
    ({ data, status }) => {
      if (status === STATUS_DONE) {
        setOwners(data);
      }
    },
    {
      schema: [userSchema],
      loadMoreAction: actions.userData.retrieveListLoadMore,
    }
  );

  useEffect(() => {
    if (isEmpty(group.regular_members)) return;

    fetchRegularMembers({
      group: [group.id],
      page_size: 20,
      q: debouncedMemberSearchText,
      view_mode: 'with_location',
    });
  }, [group, debouncedMemberSearchText]);

  useEffect(() => {
    if (isEmpty(group.owners_ids)) return;

    const ownersIDs = join(group.owners_ids, ',');
    fetchOwners({
      id__in: ownersIDs,
      page_size: 20,
      q: debouncedOwnerSearchText,
      view_mode: 'with_location',
    });
  }, [group, debouncedOwnerSearchText]);

  const isChangePrivacyDisabled = () => {
    const { permissions } = group;
    return !includes(permissions, permissionConstants.CHANGE_GROUP_PRIVACY_PERMISSION);
  };

  const deleteGroup = async () => {
    const { home_page_url_path: homePath } = currentUser;

    const [err] = await to(deleteGroupMutation.mutateAsync(group.id));

    if (err) {
      const errorMessage = getErrorMessageFromResponse(err);
      toast.error('Error', errorMessage);
      return;
    }

    trackActivity(METRICS_ACTIVITIES.ENTITY_DELETE, {
      entityType: 'group',
      entityId: group.id,
    });

    const args = queryString.parse(location.search);

    // eslint-disable-next-line promise/always-return
    const origin = get(args, 'origin');

    if (!origin) {
      history.push(homePath);
      return;
    }

    // Redirect to dashboard if the group was deleted from their detail page
    if (origin === mapRoute('groupDetails', { id: group.id })) {
      history.push(mapRoute('dashboardGroups'));
      return;
    }

    history.push(origin);
    toast.success('Success', 'Group successfully deleted!');
  };

  const userPermissions = currentUser.permissions;

  return (
    <FormPanelContainer>
      <Form
        initialValues={group}
        onSubmit={handleSubmit}
        renderButtons={() => (
          <ButtonsContainer>
            <Button route={redirectionRoute} color="secondary" size="large">
              Back
            </Button>
            <Button
              data-cy="save-group-button"
              type="submit"
              disabled={pristine || submitting}
              size="large"
            >
              Save Changes
            </Button>
          </ButtonsContainer>
        )}
      >
        <Form.Panel title="Group Details">
          <NameArea>
            <Field
              name="name"
              label="Name"
              required
              validate={fieldRequired}
              component={TextField}
            />
          </NameArea>
          {includes(userPermissions, permissionConstants.CREATE_PUBLIC_GROUP_PERMISSION) ? (
            <GroupPrivacyOptions>
              <Form.FieldLabel
                color={colors.neutral500}
                tooltipTitle={`Groups owned by mentors and organizers are always private.
                   To make this group public, add an admin as owner.`}
              >
                Group Privacy
              </Form.FieldLabel>
              <RadioButtonArea>
                <Field
                  name="is_private"
                  validate={fieldRequired}
                  component={PrivacyField}
                  disabled={isChangePrivacyDisabled()}
                  label="Private"
                />
              </RadioButtonArea>
            </GroupPrivacyOptions>
          ) : (
            <SimpleInfo>
              Group privacy:{' '}
              {group.is_private
                ? 'Private (only owners can see it)'
                : 'Public (everyone can see it)'}
            </SimpleInfo>
          )}
          <SimpleInfo>Last Edited: {moment(group.modified).format('MMM DD, YYYY')}</SimpleInfo>
          <SimpleInfo>Active Since: {moment(group.created).format('MMM DD, YYYY')}</SimpleInfo>
        </Form.Panel>

        <Form.Panel title={`Owners (${group.owners_count || 0})`} padding={0} dataCy="owners-panel">
          <Fields
            names={['new_owners', 'remove_owners']}
            currentOwnersList={owners}
            searchText={ownerSearchText}
            handleOnSearchTextChange={handleOnOwnerSearchTextChange}
            component={GroupOwnerListFields}
            fetchNextPage={fetchOwnersNextPage}
            isLoadingMore={fetchOwnersStatus === STATUS_LOADING_MORE}
            loadMoreLink={ownersListMoreLink}
          />
        </Form.Panel>

        <Form.Panel
          title={`Members (${group.members_count || 0})`}
          padding={0}
          dataCy="members-panel"
        >
          <Fields
            names={['new_members', 'remove_members', 'new_emails', 'remove_emails']}
            currentMembers={regularMembers}
            searchText={memberSearchText}
            handleOnSearchTextChange={handleOnMemberSearchTextChange}
            component={GroupUserListFields}
            fetchNextPage={fetchMembersNextPage}
            isLoadingMore={fetchRegularMembersStatus === STATUS_LOADING_MORE}
            loadMoreLink={membersListMoreLink}
          />
        </Form.Panel>

        <Form.DeletePanel
          title="Delete Group"
          deleteText={`
            Once you delete a group, it cannot be restored. Events and sessions that have
             restricted access to this group will become public.
             Please be sure about your decision.`}
          handleDelete={() =>
            confirmAlert({
              title: 'Delete Group',
              content: () => (
                <div data-cy="delete-group-confirmation-modal">
                  <div>
                    You are deleting {group.name}, a group active since{' '}
                    {moment(group.created).format('MMM D, YYYY')} with {group.members_count}{' '}
                    {join(map(owners, 'name'), ', ')}.
                  </div>
                  {!includes(currentUser.id, owners) && (
                    <div>
                      The owners will receive an email notification informing that their group has{' '}
                      been deleted. Are you sure you want to delete this group?
                    </div>
                  )}
                </div>
              ),
              confirmLabel: 'Delete',
              onConfirm: () => deleteGroup(),
            })
          }
          isEditing
        />
      </Form>
    </FormPanelContainer>
  );
};

GroupEditForm.propTypes = {
  redirectionRoute: PropTypes.string,

  currentUser: PropTypes.object,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  form: PropTypes.string,
};

const formSelector = formValueSelector('groupFormEdit');

const mapStateToProps = (state) => {
  const removedMembers = denormalize(
    formSelector(state, 'remove_members'),
    [userSchema],
    state.entities
  );

  const removedOwners = denormalize(
    formSelector(state, 'remove_owners'),
    [userSchema],
    state.entities
  );

  return {
    removedMembers,
    removedOwners,
  };
};

const mapDispatchToProps = () => ({});

const RouterGroupEditForm = withRouter(
  reduxForm({
    form: 'groupFormEdit',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    onSubmit: (
      values,
      dispatch,
      { removedMembers, currentUser, initialValues, removedOwners, onSubmitHandler }
    ) =>
      submitWithConfirmation(
        ({ proceedToSubmit, cancelSubmit }) => {
          const removingMembers = !isEmpty(removedMembers);
          const removingOwners = !isEmpty(removedOwners);
          const removedParticipants = size(removedMembers) + size(removedOwners);

          if (removingMembers || removingOwners) {
            confirmAlert({
              title: 'Removing participants from group',
              content: () => (
                <React.Fragment>
                  <RemovalDialogTextBlock>
                    You are removing {removedParticipants}{' '}
                    {removedParticipants === 1 ? 'participant' : 'participants'} from{' '}
                    {initialValues.name}.
                  </RemovalDialogTextBlock>
                  <RemovalDialogTextBlock>
                    {removingMembers && (
                      <SimpleInfo>
                        Members:
                        {map(removedMembers, (m) => (
                          <div key={m.id}>{m.name}</div>
                        ))}
                      </SimpleInfo>
                    )}
                    {removingOwners && (
                      <SimpleInfo>
                        Owners:
                        {map(removedOwners, (o) => (
                          <div key={o.id}>{o.name}</div>
                        ))}
                      </SimpleInfo>
                    )}
                  </RemovalDialogTextBlock>
                  <RemovalDialogTextBlock>
                    {removedParticipants === 1 ? 'This participant' : 'These participants'} will{' '}
                    stop receiving invitations, viewing events and being able to book sessions that{' '}
                    are restricted to this group.
                  </RemovalDialogTextBlock>
                  {!includes(initialValues.owners, currentUser.id) && (
                    <RemovalDialogTextBlock>
                      The owners will receive an email notification about all the changes made to
                      their group.
                    </RemovalDialogTextBlock>
                  )}
                </React.Fragment>
              ),
              confirmLabel: 'Remove',
              onConfirm: () => proceedToSubmit(),
              onClose: () => cancelSubmit(),
            });
          } else {
            proceedToSubmit();
          }
        },
        () => {
          const payload = {
            name: values.name,
            new_owners: values.new_owners,
            remove_owners: values.remove_owners,
            new_members: values.new_members,
            remove_members: values.remove_members,
            is_private: values.is_private,
          };

          return onSubmitHandler(payload);
        }
      ),
    onSubmitSuccess: (result, dispatch, { onSubmitSuccessHandler }) =>
      onSubmitSuccessHandler(result),
    onSubmitFail: handleFailureWithConfirmation(() => {
      toast.error(
        'Group could not be updated, please make sure you are sending correct info.',
        'If you think something is wrong, please contact PlusPlus support.'
      );
    }),
  })(GroupEditForm)
);

export default connect(mapStateToProps, mapDispatchToProps)(RouterGroupEditForm);
