import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';
import { ApiURLs, fetchURL } from '~/services/requests-base';
import queryString from 'query-string';

const fetchGroupList = async (filters = {}) => {
  let url = ApiURLs['internalgroups_api:list_create']();

  if (filters) {
    url += `?${queryString.stringify(filters)}`;
  }

  const { data: response } = await fetchURL(url);
  return response;
};

const fetchGroupDetail = async (id: number, viewMode?: string) => {
  let url = ApiURLs['internalgroups_api:retrieve_update_delete'](id);

  if (viewMode) {
    url += `?view_mode=${viewMode}`;
  }

  const { data: response } = await fetchURL(url);
  return response;
};

export const groups = createQueryKeys('groups', {
  list: (filters = {}) => ({
    queryKey: ['groups', filters],
    queryFn: () => fetchGroupList(filters),
  }),
  detail: (id: number, viewMode?: string) => ({
    queryKey: ['groups', id, viewMode],
    queryFn: () => fetchGroupDetail(id, viewMode),
  }),
});

export type groupsQueryKeys = inferQueryKeys<typeof groups>;
