import { FormControl } from '@mui/material';
import MuiSelect from '@mui/material/Select';
import PropTypes from 'prop-types';
import React from 'react';

import InputLabel from '~/app/inputs/components/InputLabel';

const Select = ({ label, inputProps: { id, ...inputProps }, ...props }) => {
  if (label) {
    return (
      <FormControl fullWidth data-cy={'select'}>
        <InputLabel shrink id={`label-${id}`}>
          {label}
        </InputLabel>
        <MuiSelect
          notched
          label={label}
          labelId={`label-${id}`}
          inputProps={{ id, ...inputProps }}
          {...props}
        />
      </FormControl>
    );
  }

  return <MuiSelect inputProps={{ id, ...inputProps }} {...props} />;
};

Select.propTypes = {
  label: PropTypes.string,
  inputProps: PropTypes.object,
};

export default Select;
