import { Box, Card, styled, Typography } from '@mui/material';
import { ContentItemCardLearningType } from '~/common/components/ContentItemCard/ContentItemCardLearningType';
import Icon from '~/app/shared/components/Icon';
import colors from '~/services/colors';
import { useLearningTypeCardVariant } from '~/common/hooks/useLearningTypeCardVariant';
import { Channel } from '~/features/channels/types';
import { get } from 'lodash-es';

const StackedCard = styled(Card)({
  position: 'relative',
  backgroundColor: colors.neutral0,
  marginTop: '-6px',
  height: '9px',
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
});

type TrackContentItemCardLearningAreaProps = {
  learningType: string;
  steps?: number;
  channel?: Channel;
};

export function TrackContentItemCardLearningArea(props: TrackContentItemCardLearningAreaProps) {
  const { learningType, channel, steps } = props;

  const learningTypeVariant = useLearningTypeCardVariant(learningType);
  const channelThemeColors = get(channel, 'theme.config.colors');
  const stepsLabel = steps ? `- ${steps} step${steps > 1 ? 's' : ''}` : '';

  return (
    <Box position="relative">
      <ContentItemCardLearningType
        icon={
          <Icon name={learningTypeVariant.icon} width={14} height={14} color={colors.neutral0} />
        }
        sx={{
          position: 'relative',
          zIndex: 2,
          backgroundColor: channelThemeColors?.solid ?? colors.action700,
        }}
      >
        <Typography variant="body2" component="span" noWrap color={colors.neutral0}>
          {learningTypeVariant.label} {stepsLabel}
        </Typography>
      </ContentItemCardLearningType>

      <Box position="absolute" width="100%" zIndex={1}>
        <StackedCard
          sx={{
            zIndex: 2,
          }}
        />
        <StackedCard
          sx={{
            zIndex: 1,
            width: 'calc(100% - 4px)',
          }}
        />
      </Box>
    </Box>
  );
}
