// eslint-disable-next-line no-unused-vars
import React from 'react';

import { useCurrentExpressionWithoutPage } from '~/app/backoffice/utils';
import { ApiURLs } from '~/services/requests-base';
import { STATUS_DONE } from '~/app/shared/constants';
import { useState } from 'react';
import Modal, { ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import styled from 'styled-components';
import colors from '~/services/colors';
import { useAssignmentsContext } from './Context';
import Text from '~/app/shared/components/Text';
import { useCurrentUser } from '~/app/shared/hooks';
import { map, includes, concat, without } from 'lodash-es';
import { Box, Stack } from '@mui/material';
import CheckboxInput from '~/app/inputs/components/SquareCheckboxInput';
import PropTypes from 'prop-types';
import Button from '~/app/shared/components/Button';
import { titleCase } from '~/services/utils';

const ModalContent = styled.div`
  padding: 20px 40px 5px 40px;
  overflow-y: auto;
`;

const CheckboxContainer = styled.div`
  margin-top: 6px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
`;

const ExportWithCustomAttrModal = ({ handleSubmit, handleClose }) => {
  const currentUser = useCurrentUser();
  const { exportable_people_integration_fields: exportablePeopleIntegrationFields } = currentUser;
  const [checkedFields, setCheckedFields] = useState(exportablePeopleIntegrationFields);

  const toggleField = (field) => {
    setCheckedFields((checkedFields) => {
      if (includes(checkedFields, field)) {
        return without(checkedFields, field);
      }

      return concat(checkedFields, field);
    });
  };

  const handleOnSubmit = () => {
    const params = new URLSearchParams();
    for (const field of checkedFields) {
      params.append('required_fields', field);
    }

    const queryString = params.toString();

    handleSubmit(queryString);
    handleClose();
  };

  return (
    <Modal title="Export with custom attributes" handleClose={handleClose} width={500}>
      <ModalContent>
        <Text block size="h4" color={colors.neutral900}>
          Which fields do you want to add to the export?
        </Text>

        <Stack pt={1} pb={2} gap={2}>
          <Box>
            {map(exportablePeopleIntegrationFields, (field) => {
              const isChecked = includes(checkedFields, field);
              return (
                <CheckboxContainer key={field}>
                  <CheckboxInput
                    checked={isChecked}
                    onChange={() => toggleField(field)}
                    label={titleCase(field)}
                  />
                </CheckboxContainer>
              );
            })}
          </Box>
        </Stack>
      </ModalContent>
      <ModalFooter variant="buttons">
        <ModalFooterButton color="secondary" onClick={handleClose} size="small">
          Cancel
        </ModalFooterButton>
        <ModalFooterButton
          size="small"
          onClick={handleOnSubmit}
          disabled={checkedFields.length === 0}
        >
          Export CSV
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

const Actions = () => {
  const expression = useCurrentExpressionWithoutPage();
  const [showPeopleIntegrationModal, setShowPeopleIntegrationModal] = useState(false);
  const { fetchFiltersStatus, exportCSVURL } = useAssignmentsContext();

  if (fetchFiltersStatus !== STATUS_DONE) return null;

  const buildExporWithCustomAttrUrl = (requiredFields) => {
    const baseUrl = ApiURLs['content_items_api:assignments_export_rql']();
    const url = `${baseUrl}?${expression}&${requiredFields}`;
    window.open(url, '_self');
  };

  const buildExportUrl = () => {
    const baseUrl = ApiURLs[exportCSVURL]();
    const url = `${baseUrl}?${expression}`;
    window.open(url, '_self');
  };

  return (
    <>
      <Button onClick={() => setShowPeopleIntegrationModal(true)}>
        Export with custom attributes
      </Button>
      <Button onClick={buildExportUrl} target="_self" variant="contained">
        Export CSV
      </Button>
      {showPeopleIntegrationModal && (
        <ExportWithCustomAttrModal
          handleClose={() => setShowPeopleIntegrationModal(false)}
          handleSubmit={buildExporWithCustomAttrUrl}
        />
      )}
    </>
  );
};

ExportWithCustomAttrModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export { Actions };
