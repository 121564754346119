import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, Fields, formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';

import { userSchema } from '~/app/entities/schema';
import { getDataFromState } from '~/app/entities/utils';
import GroupOwnerListFields from '~/app/groups/components/GroupOwnerListFields';
import GroupUserListFields from '~/app/groups/components/GroupUserListFields';
import PrivacyField from '~/app/groups/components/PrivacyField';
import InputLabel from '~/app/inputs/components/InputLabel';
import TextField from '~/app/inputs/components/TextField';
import { toast } from '~/app/notifications/components/NotificationCenter';
import colors from '~/services/colors';
import Button from '~/app/shared/components/Button';
import Loading from '~/app/shared/components/Loading';
import MediaPoint from '~/app/shared/components/MediaPoint';
import Form from '~/app/shared/components/OldForm';
import { STATUS_LOADING } from '~/app/shared/constants';
import * as formValidations from '~/app/shared/formValidations';
import { useFormPreventTransition, useOriginRoute } from '~/app/shared/hooks';
import * as permissionConstants from '~/app/shared/permissions';

import { includes, isEmpty } from 'lodash-es';

const FormPanelContainer = styled.div`
  width: 300px;
  border-radius: 8px;
  align-self: center;
  ${MediaPoint.Tablet} {
    width: 500px;
  }
  ${MediaPoint.DesktopSm} {
    width: 600px;
  }
`;

const SimpleInfo = styled.div`
  color: ${colors.neutral500};
  font-size: 14px;
  margin-bottom: 8px;
`;

const GroupPrivacyOptions = styled.div`
  margin-bottom: 16px;
`;

const NameArea = styled.div`
  margin-bottom: 16px;
`;

const ButtonsContainer = styled.div`
  margin-top: 32px;
  display: flex;
  align-self: center;
  justify-content: center;
  gap: 32px;
`;

const PrivacyText = styled.div`
  color: ${colors.neutral500};
  font-size: 14px;
  margin-bottom: 8px;
  margin-top: 15px;
`;

const RadioButtonArea = styled.div`
  margin-top: 10px;
`;

const fieldRequired = formValidations.required();

const GroupCreateForm = ({
  handleSubmit,
  currentUser,
  groupRequestStatus,
  fieldValues: { is_private: isPrivate },
  form,
}) => {
  const { home_page_url_path: homePath } = currentUser;
  const redirectionRoute = useOriginRoute(homePath);

  useFormPreventTransition(form);

  if (groupRequestStatus === STATUS_LOADING) return <Loading />;
  const userPermissions = currentUser.permissions;

  return (
    <FormPanelContainer>
      <Form
        onSubmit={handleSubmit}
        renderButtons={() => (
          <ButtonsContainer>
            <Button route={redirectionRoute} type="button" color="secondary" size="large">
              Back
            </Button>
            <Button type="submit" size="large" data-cy="create-group-button">
              Create Group
            </Button>
          </ButtonsContainer>
        )}
      >
        <Form.Panel title="Group Details">
          <NameArea>
            <Field
              name="name"
              label="Name"
              required
              validate={fieldRequired}
              component={TextField}
            />
          </NameArea>
          {includes(userPermissions, permissionConstants.CREATE_PUBLIC_GROUP_PERMISSION) &&
          includes(['admin', 'organizer'], currentUser.role) ? (
            <GroupPrivacyOptions>
              <InputLabel>Group Privacy</InputLabel>
              <RadioButtonArea>
                <Field
                  name="is_private"
                  validate={fieldRequired}
                  component={PrivacyField}
                  label="Private"
                />
              </RadioButtonArea>
              <PrivacyText>
                {isPrivate === 'true'
                  ? 'Only owners can see this group.'
                  : 'Everyone can see this group.'}
              </PrivacyText>
            </GroupPrivacyOptions>
          ) : (
            <SimpleInfo>This group is private, only owners can see it.</SimpleInfo>
          )}
        </Form.Panel>
        <Form.Panel title="Owners" padding={0} dataCy="owners-panel">
          <Fields names={['new_owners']} component={GroupOwnerListFields} />
        </Form.Panel>
        <Form.Panel title="Members" padding={0} dataCy="members-panel">
          <Fields names={['new_members']} component={GroupUserListFields} addOnlyMode />
        </Form.Panel>
      </Form>
    </FormPanelContainer>
  );
};

GroupCreateForm.propTypes = {
  groupRequestStatus: PropTypes.string,
  currentUser: PropTypes.object,
  handleSubmit: PropTypes.func,
  fieldValues: PropTypes.object,
  form: PropTypes.string,
};

const formSelector = formValueSelector('groupFormCreate');

const mapStateToProps = (state) => {
  const membersRequestState = getDataFromState('groupMembersList', state, [userSchema]);

  return {
    members: membersRequestState.data,
    membersRequestStatus: membersRequestState.status,
    currentUser: state.user.currentUser,
    fieldValues: {
      is_private: formSelector(state, 'is_private'),
    },
    initialValues: { is_private: 'true', new_owners: [state.user.currentUser.id] },
  };
};

export default connect(mapStateToProps)(
  withRouter(
    reduxForm({
      form: 'groupFormCreate',
      onSubmit: async (values, _dispatch, { onSubmitHandler, currentUser }) => {
        const { name, new_owners, new_members, is_private } = values;

        const payload = {
          name,
          new_owners: !isEmpty(new_owners) ? new_owners : [currentUser.id],
          new_members,
          is_private: is_private === 'true',
          owner_id: currentUser.id,
        };

        return await onSubmitHandler(payload);
      },
      onSubmitSuccess: (result, dispatch, { onSubmitSuccessHandler }) =>
        onSubmitSuccessHandler(result),
      onSubmitFail: () => {
        toast.error(
          'Group could not be created, please make sure you are sending correct info.',
          'If you think something is wrong, please contact PlusPlus support.'
        );
      },
    })(GroupCreateForm)
  )
);
