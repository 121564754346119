import PropTypes from 'prop-types';
import React from 'react';
import { DatePicker } from '@mui/x-date-pickers-pro';
import moment from 'moment';

import LocationSelectField from '~/app/inputs/components/LocationSelectField';
import TextInput from '~/app/inputs/components/TextInput';
import colors from '~/services/colors';
import HelpLink from '~/app/shared/components/HelpLink';
import Text from '~/app/shared/components/Text';
import { useCurrentUser, useLabels } from '~/app/shared/hooks';
import { capitalize } from 'lodash-es';
import { Stack } from '@mui/material';

const ScheduleTrackReviewBody = ({ info, setInfo }) => {
  const { label_track: labelTrack } = useLabels();
  const { is_slack_integration_active: isSlackIntegrationActive } = useCurrentUser();

  const slackChannelHelpUrl =
    'https://help.plusplus.app/en/articles/6741349-plusplus-101-offer-cohort-based-training-with-scheduled-tracks#h_8b9d3fb284';

  return (
    <Stack spacing={2} p="20px" sx={{ minHeight: '420px' }}>
      <Stack spacing={0}>
        <Text size="h5" color={colors.neutral600}>
          Let&#39;s personalize this track, so that it stands apart. You&#39;ll still be able to
          further customize it before you publish it.
        </Text>
      </Stack>

      <TextInput
        label={`Scheduled ${capitalize(labelTrack)} Name`}
        inputProps={{
          name: 'name',
          value: info.name,
          onChange: (event) => setInfo({ ...info, name: event.target.value }),
        }}
        required
      />
      {isSlackIntegrationActive && (
        <Stack spacing={0}>
          <TextInput
            label="Slack Channel"
            inputProps={{
              name: 'slack-channel',
              value: info.slackChannel,
              onChange: (event) => setInfo({ ...info, slackChannel: event.target.value }),
            }}
            error={Boolean(info.slackChannelError)}
            helperText={info.slackChannelError || null}
          />
          <Text size="h5" color={colors.neutral600}>
            If this channel doesn&#39;t exist, we&#39;ll create it for you. {capitalize(labelTrack)}{' '}
            participants will be auto-invited to it.
            <HelpLink url={slackChannelHelpUrl} />
          </Text>
        </Stack>
      )}
      <LocationSelectField
        label="Location"
        input={{
          name: 'location',
          value: info.locationId,
          onChange: (value) => setInfo({ ...info, locationId: value }),
        }}
      />
      <TextInput
        label="Enrollment Cap"
        type="number"
        inputProps={{
          name: 'capacity',
          value: info.capacity,
          onChange: (event) => setInfo({ ...info, capacity: event.target.value }),
          min: 0,
        }}
      />
      <Stack direction="row" spacing={2}>
        <DatePicker
          label="Starts On"
          value={info.starts_at ? moment(info.starts_at) : null}
          onChange={(value) => setInfo({ ...info, starts_at: value?.toISOString() })}
          slotProps={{
            field: {
              required: true,
            },
          }}
          maxDate={info.ends_at ? moment(info.ends_at) : undefined}
          required
        />
        <DatePicker
          label="Ends On"
          value={info.ends_at ? moment(info.ends_at) : null}
          onChange={(value) => setInfo({ ...info, ends_at: value?.toISOString() })}
          minDate={info.starts_at ? moment(info.starts_at) : undefined}
          slotProps={{
            field: {
              required: true,
            },
          }}
        />
      </Stack>
    </Stack>
  );
};

ScheduleTrackReviewBody.propTypes = {
  info: PropTypes.object,
  setInfo: PropTypes.func,
};

export default ScheduleTrackReviewBody;
