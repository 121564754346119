import PropTypes from 'prop-types';
import React from 'react';

import Select from '~/app/inputs/components/Select';
import { filter, includes, map } from 'lodash-es';
import { MenuItem } from '@mui/material';

const filterOptionsByEventValue = (options, event, multiple) =>
  filter(options, (option) => {
    if (!multiple) {
      return option.value === event.target.value;
    }
    return includes(event.target.value, option.value);
  });

const SelectField = (props) => {
  const {
    input: { onChange, name, ...input },
    multiple,
    options,
    inputProps: { id, ...inputProps },
    ...remainingProps
  } = props;

  return (
    <Select
      onChange={(event) =>
        onChange(event.target.value, filterOptionsByEventValue(options, event, multiple))
      }
      inputProps={{ ...inputProps, id: id || name, name, title: name }}
      SelectDisplayProps={{ title: `${name}-clickable` }}
      MenuProps={{ title: `${name}-options` }}
      multiple={multiple}
      {...input}
      {...remainingProps}
      data-cy={name}
    >
      {map(options, ({ value, label }) => (
        <MenuItem key={`${input.name}-${value}`} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

SelectField.defaultProps = {
  fullWidth: true,
  size: 'small',
  displayEmpty: true,
  inputProps: {},
};

SelectField.propTypes = {
  input: PropTypes.object,
  inputProps: PropTypes.object,
  meta: PropTypes.object,
  options: PropTypes.array,
  fullWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  multiple: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf(['small', 'medium'])]),
};

export default SelectField;
