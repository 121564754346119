import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Button, Divider } from '@mui/material';
import { grey } from '@mui/material/colors';

import { useIsPreviewQueryParam } from '~/app/shared/hooks';
import { useEnsureContentItemAssignmentQuery } from '~/features/contentitems/hooks/useEnsureContentItemAssignmentQuery';
import colors from '~/services/colors';

import { useTrackContentConsumptionCtx } from '../contexts/TrackContentConsumptionCtx';
import { useTrackContentNavigationCtx } from '../contexts/TrackContentNavigationCtx';

import { TrackItemConsumptionActions } from './TrackItemConsumptionActions';
import { TrackItemPreviewActions } from './TrackItemPreviewActions';

export function TrackNavigationBar() {
  const { currentTrackItem, currentSection } = useTrackContentConsumptionCtx();
  const { current: currentNavigation, onGoBack, onAdvance } = useTrackContentNavigationCtx();

  const isPreviewMode = useIsPreviewQueryParam();

  const { data: contentItem } = useEnsureContentItemAssignmentQuery(currentTrackItem?.content_item);

  const assignment = contentItem?.user_assignment;

  const isFirstPage = currentNavigation.page === 1;
  const isSection = currentSection != null;

  const canDisplayPreviewActions = isPreviewMode && contentItem != null;
  const canDisplayItemActions = contentItem != null && assignment != null;

  return (
    <Box>
      <Divider />

      <Box
        height="70px"
        paddingX="32px"
        display="flex"
        alignItems="center"
        bgcolor={grey[100]}
        justifyContent="space-between"
      >
        <Button
          variant="outlined"
          startIcon={<ChevronLeft />}
          onClick={onGoBack}
          disabled={isFirstPage}
          sx={{
            backgroundColor: colors.neutral0,
            '&:hover': {
              backgroundColor: colors.neutral50,
            },
          }}
        >
          Previous
        </Button>

        {isSection ? (
          <Button variant="contained" endIcon={<ChevronRight />} onClick={onAdvance}>
            Next
          </Button>
        ) : canDisplayPreviewActions ? (
          <TrackItemPreviewActions contentItem={contentItem} />
        ) : canDisplayItemActions ? (
          <TrackItemConsumptionActions contentItem={contentItem} assignment={assignment} />
        ) : null}
      </Box>
    </Box>
  );
}
