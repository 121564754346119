import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import { HR } from '~/app/shared';
import { ButtonWrapper } from '~/app/shared/components/Button';
import Clicker from '~/app/shared/components/Clicker';
import CustomIcon from '~/app/shared/components/Icon';
import { isString } from 'lodash-es';

const ListItem = styled.li`
  max-height: 40px;
  font-size: 16px;
  color: ${colors.neutral600};
  display: flex;

  button {
    text-align: left;
  }

  &:hover {
    background-color: ${colors.neutral50};
    a {
      color: ${colors.neutral600};
    }
  }
`;

const ListItemButton = styled(ButtonWrapper)`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border: 0;
  padding: 8px 12px;
  color: ${colors.neutral600};

  &[disabled] {
    cursor: not-allowed;
    color: ${colors.neutral400};
  }
`;

const ListItemText = styled.span`
  margin-left: 8px;
  ${({ color }) => `color: ${color}`}
`;

const MenuItem = (props) => {
  const {
    title,
    icon: Icon,
    color,
    textColor,
    children,
    targetBlank,
    type,
    route,
    disabled,
    ...rest
  } = props;

  const defaultButtonProps = {
    fontSize: 16,
    fullWidth: true,
    noBorder: true,
    ...(targetBlank && { target: '_blank' }),
  };

  const buttonProps = {
    ...defaultButtonProps,
    ...rest,
    type,
    route,
    disabled,
  };

  const getIconColor = () => {
    if (disabled) {
      return colors.neutral400;
    }

    return color ?? colors.neutral600;
  };

  return (
    <ListItem>
      <ListItemButton {...buttonProps}>
        {/* FIXME https://app.asana.com/0/0/1201921124577389/f */}
        {Icon && isString(Icon) ? (
          <CustomIcon name={Icon} width={16} height={16} color={getIconColor()} />
        ) : null}
        {Icon && !isString(Icon) ? (
          <Icon sx={{ width: 17, height: 17, color: getIconColor() }} />
        ) : null}
        <ListItemText color={textColor}>{title || children}</ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

MenuItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  color: PropTypes.string,
  textColor: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  targetBlank: PropTypes.bool,
  type: PropTypes.string,
  route: PropTypes.string,
  disabled: PropTypes.bool,
};

const Wrapper = styled.div`
  position: relative;
`;

export const List = styled.ul`
  ${({ opened }) => !opened && 'display: none'};

  ${({ upwards }) => upwards && 'bottom: 0'};
  position: absolute;
  z-index: 1000;
  min-width: 160px;
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border-radius: none;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  white-space: nowrap;

  float: left;

  ${({ openRight }) => !openRight && 'right: 5px;'}
  ${({ listFullWidth }) => listFullWidth && 'right: 0; width: 100%;'}
`;

const DropdownButtonWrapper = styled.div``;

const DropdownButton = styled(Clicker)`
  height: 100%;

  color: ${colors.neutral200};

  &:hover,
  &:focus {
    color: ${colors.action600};

    svg path {
      fill: ${colors.action600};
    }
  }
`;

const DropDownMenu = ({
  icon,
  children,
  openRight,
  className,
  fontSize,
  color,
  buttonContent,
  renderButton,
  listFullWidth,
  buttonAriaLabel,
  upwards,
  stopPropagationOnClick,
}) => {
  const [open, setOpen] = useState(false);
  const buttonNode = useRef();
  const listNode = useRef();

  useEffect(() => {
    const handleClick = (e) => {
      const { current } = buttonNode;
      if (current && !current.contains(e.target)) {
        setOpen(false);
        e.stopPropagation();
      }
    };

    // Close list if focusing on other items
    const handleItemFocus = (e) => {
      const { current } = listNode;
      if (current && !current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleClick, false);
    document.addEventListener('focusin', handleItemFocus, false);

    return () => {
      document.removeEventListener('click', handleClick, false);
      document.removeEventListener('focusin', handleItemFocus, false);
    };
  }, []);

  const handleButtonClick = (e) => {
    if (stopPropagationOnClick) e.stopPropagation();
    return setOpen((o) => !o);
  };

  return (
    <Wrapper className={className} aria-label="Dropdown menu">
      <DropdownButtonWrapper ref={buttonNode}>
        {renderButton ? (
          renderButton({ toggleMenu: handleButtonClick, isOpen: open })
        ) : (
          <DropdownButton
            onClick={handleButtonClick}
            fontSize={fontSize}
            variant="text"
            aria-label={buttonAriaLabel}
            data-testid={buttonAriaLabel}
            data-cy={'context-menu-button'}
          >
            {icon && (
              <CustomIcon
                name={icon}
                width={fontSize || 20}
                height={fontSize || 20}
                color={color || colors.neutral400}
                noTransition
              />
            )}
            {buttonContent}
          </DropdownButton>
        )}
      </DropdownButtonWrapper>
      <List
        upwards={upwards}
        opened={open}
        openRight={openRight}
        ref={listNode}
        listFullWidth={listFullWidth}
        data-testid="DropdownOptions"
        data-cy="dropdown-options"
      >
        {children}
      </List>
    </Wrapper>
  );
};

DropDownMenu.propTypes = {
  buttonContent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  renderButton: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  icon: PropTypes.string,
  openRight: PropTypes.bool,
  className: PropTypes.string,
  fontSize: PropTypes.number,
  color: PropTypes.string,
  listFullWidth: PropTypes.bool,
  buttonAriaLabel: PropTypes.string,
  upwards: PropTypes.bool,
  stopPropagationOnClick: PropTypes.bool,
};

DropDownMenu.defaultProps = {
  fontSize: 24,
  color: colors.neutral400,
  renderButton: null,
  stopPropagationOnClick: false,
};

DropDownMenu.Item = MenuItem;
DropDownMenu.Separator = function Separator() {
  return <HR color={colors.neutral200} />;
};

export default DropDownMenu;
