import PropTypes from 'prop-types';
import React from 'react';
import structure from 'redux-form/lib/structure/plain';

import colors from '~/services/colors';
import { generateGuid } from '~/services/utils';
import ButtonLink from '~/app/shared/components/ButtonLink';
import Text from '~/app/shared/components/Text';
import { useCurrentUser } from '~/app/shared/hooks';
import { forEach } from 'lodash-es';

const inputGetter = (nameMapping, names, fields) => (key) => {
  const mapping = {};
  forEach(names, (n) => {
    mapping[n] = structure.getIn(fields, n);
  });

  return mapping[nameMapping[key]].input;
};

const CalendarManagerField = ({ nameMapping, names, ...fields }) => {
  const getFieldInput = (key) => {
    return inputGetter(nameMapping, names, fields)(key);
  };

  const currentUser = useCurrentUser();

  const handleSwitchCalendar = () => {
    const idInput = getFieldInput('id');
    const calendarOwnerInput = getFieldInput('resourceOwner');
    const roomsInfoInput = getFieldInput('roomsInfo');
    const roomsInput = getFieldInput('rooms');

    const newId = generateGuid();
    idInput.onChange(newId);
    calendarOwnerInput.onChange(currentUser);
    roomsInfoInput.onChange('');
    roomsInput.onChange([]);
  };

  const calendarOwnerInput = getFieldInput('resourceOwner');
  let eventManager;
  if (calendarOwnerInput.value.id === currentUser.id) {
    eventManager = 'you';
  } else if (calendarOwnerInput.value) {
    eventManager = calendarOwnerInput.value.name;
  } else {
    eventManager = 'PlusPlus';
  }
  return (
    <>
      <Text size="h5" color={colors.neutral500}>
        Calendar Event managed by {eventManager}.
      </Text>
      {calendarOwnerInput.value.id !== currentUser.id && (
        <>
          {' '}
          <ButtonLink variant="success" onClick={handleSwitchCalendar}>
            Switch to your Calendar
          </ButtonLink>
        </>
      )}
    </>
  );
};

CalendarManagerField.propTypes = {
  names: PropTypes.array,
  nameMapping: PropTypes.object,
};

export default CalendarManagerField;
