import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, Fields } from 'redux-form';
import styled from 'styled-components';

import Copy from '~/app/shared/Copy';
import { WATCH_LINK_METHOD } from '~/app/event-shared/constants';
import CalendarMultipleResourcesFields from '~/app/inputs/components/CalendarMultipleResourcesFields';
import DateTimeField from '~/app/inputs/components/DateTimeField';
import DurationField, { validateDuration } from '~/app/inputs/components/DurationField';
import InputLabel from '~/app/inputs/components/InputLabel';
import SelectField from '~/app/inputs/components/SelectField';
import TextField from '~/app/inputs/components/TextField';
import colors from '~/services/colors';
import Button from '~/app/shared/components/Button';
import Clicker from '~/app/shared/components/Clicker';
import { FormFieldGroup } from '~/app/shared/components/Form';
import Icon from '~/app/shared/components/Icon';
import InfoText from '~/app/shared/components/InfoText';
import MediaPoint from '~/app/shared/components/MediaPoint';
import OldForm from '~/app/shared/components/OldForm';
import Text from '~/app/shared/components/Text';
import * as formValidations from '~/app/shared/formValidations';
import { useCurrentUser, useFormSelector, useLabels } from '~/app/shared/hooks';
import { ALLOW_SWITCHING_CALENDAR_OWNERSHIP_IN_EVENTS } from '~/app/shared/permissions';
import { get, includes, isArray, toLower, values } from 'lodash-es';

import CalendarManagerField from './CalendarManagerField';

const validateRequired = OldForm.validations.required();

const TimeslotFieldWrapper = styled.div`
  border: 1px solid ${colors.neutral200};
  padding: 16px;
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

const TimeslotHeader = styled(Clicker)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${({ isOpen }) => isOpen && 'margin-bottom: 16px;'}
`;

const ClosedTimeslotPreviewWrapper = styled.div`
  display: flex;
`;

const TimeslotPreviewGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TimeslotPreviewSeparator = styled.div`
  width: 1px;
  background-color: ${colors.neutral200};
  margin: 0 16px;
`;

const TimeslotPreviewRightGroup = styled.div`
  max-width: 363px;
`;

const EllipsisWrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const TopGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const RoomsWrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
`;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${MediaPoint.Tablet} {
    flex-direction: row;
  }
`;

const LargeFieldContainer = styled.div`
  width: 100%;

  ${MediaPoint.Tablet} {
    width: 66%;
  }
`;

const SmallFieldContainer = styled.div`
  width: 100%;

  ${MediaPoint.Tablet} {
    ${({ isCompactMode }) => isCompactMode && 'width: 33%;'}
    ${({ isCompactMode }) => !isCompactMode && 'padding-left: 16px;'}
    ${({ isCompactMode }) => !isCompactMode && 'display: flex;'}
    ${({ isCompactMode }) => !isCompactMode && 'flex-direction: column;'}
    ${({ isCompactMode }) => !isCompactMode && 'flex: 1'}
  }
`;

const ExtraInfoRowWrapper = styled.div`
  ${MediaPoint.Tablet} {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    > :first-child {
      flex: 1;
    }
  }
`;

const DeleteTimeslotWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
  ${MediaPoint.Tablet} {
    padding: 4px 0 4px 84px;
  }
`;

const WatchLinkWrapper = styled.div`
  display: flex;
  margin: 0 -8px 16px;
  > * {
    padding: 0 8px;
  }
  > :last-child {
    flex: 1;
    margin-top: 8px;
  }
`;

const LinkProviderWrapper = styled.div`
  margin-top: 16px;
  min-width: 180px;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;

  > * + * {
    padding-left: 8px;
  }
`;

const NewTimeslotField = ({
  isCompactMode,
  name,
  locationId,
  isOnline,
  onRemove,
  index,
  timeslot,
  formName,
  change,
  isOpen,
  setOpen,
  initialContentWatchLink,
  isEditing,
}) => {
  const [initialWatchLinkMethod] = useState(timeslot.watch_link_method);
  const [initialWatchLink] = useState(timeslot.watch_link ?? initialContentWatchLink);

  const currentUser = useCurrentUser();
  const { label_event_type: labelEventType } = useLabels();

  const permissions = get(currentUser, 'permissions', []);
  const googleCalendarEnabled = get(currentUser, 'sync_to_google_calendar');
  const allowBookCalendarRooms = get(currentUser, 'use_calendar_resources_for_events');
  const allowSwitchCalendarOwner = includes(
    permissions,
    ALLOW_SWITCHING_CALENDAR_OWNERSHIP_IN_EVENTS
  );
  const { starts_at: startsAt, duration, rooms_info: roomsInfo, watch_link: watchLink } = timeslot;

  // If we are using isCompactMode, just use the fieldName; otherwise, concatenate it with the timeslot name
  const getFieldName = (fieldName) => (isCompactMode ? fieldName : `${name}.${fieldName}`);

  const fieldNameMapping = {
    id: getFieldName('id'),
    resourceOwner: getFieldName('google_calendar_calendar_owner'),
    roomsInfo: getFieldName('rooms_info'),
    startsAt: getFieldName('starts_at'),
    duration: getFieldName('duration'),
    watch_link_method: getFieldName('watch_link_method'),
    watch_link: getFieldName('watch_link'),
    rooms: getFieldName('rooms'),
  };
  const validateWatchLink = () => {
    if (!isOnline) return;
    // This field is not required if the calendar integration is ON
    // or if we're in the event type page
    if (!allowBookCalendarRooms && !isCompactMode) {
      return [formValidations.url, validateRequired];
    }
    return [formValidations.url];
  };

  const watchLinkOptions = {
    custom: { value: WATCH_LINK_METHOD.custom, label: 'Custom' },
  };

  if (allowBookCalendarRooms && googleCalendarEnabled) {
    watchLinkOptions.google = { value: WATCH_LINK_METHOD.google, label: 'Google Meet' };
  } else if (allowBookCalendarRooms) {
    watchLinkOptions.google = { value: WATCH_LINK_METHOD.google, label: 'MS Teams' };
  }

  const watchLinkMethodFieldName = getFieldName('watch_link_method');
  const watchLinkMethod = useFormSelector(formName, watchLinkMethodFieldName);
  const watchLinkValue = useFormSelector(formName, fieldNameMapping.watch_link);

  if (!watchLinkMethod && allowBookCalendarRooms)
    change(watchLinkMethodFieldName, WATCH_LINK_METHOD.google);

  let watchLinkMethodInfoText;
  if (watchLinkMethod === WATCH_LINK_METHOD.custom) {
    watchLinkMethodInfoText = 'Manually provide a call link';
  } else if (
    watchLinkMethod &&
    allowBookCalendarRooms &&
    (!initialWatchLink || (initialWatchLinkMethod === WATCH_LINK_METHOD.custom && initialWatchLink))
  ) {
    const watchLinkMethodLabel = watchLinkOptions[watchLinkMethod].label;
    watchLinkMethodInfoText = !isCompactMode
      ? `Link will be auto generated using ${watchLinkMethodLabel} as soon as you publish this event.`
      : `Link will be auto generated using ${watchLinkMethodLabel} as soon as you publish events from this ${toLower(
          labelEventType
        )}.`;
  }

  const onChangeWatchLinkMethod = (event, newValue, previousValue) => {
    if (watchLinkValue !== initialWatchLink) {
      change(fieldNameMapping.watch_link, initialWatchLink);
    } else if (
      previousValue === WATCH_LINK_METHOD.custom &&
      newValue === WATCH_LINK_METHOD.google
    ) {
      change(fieldNameMapping.watch_link, '');
    }
  };

  const TimeslotForm = (
    <>
      <TopGroup>
        <FieldsContainer>
          {!isCompactMode && (
            <LargeFieldContainer>
              {/* This field validation happens at form validate function */}
              <Field
                isNewForm
                id={fieldNameMapping.startsAt}
                name={fieldNameMapping.startsAt}
                component={DateTimeField}
                validate={[validateRequired]}
                freeSolo
              />
            </LargeFieldContainer>
          )}
          <SmallFieldContainer isCompactMode={isCompactMode}>
            <Field
              inputId={fieldNameMapping.duration}
              label={isCompactMode ? 'Expected Duration (H:MM)' : 'Duration (H:MM)'}
              required={!isCompactMode}
              name={fieldNameMapping.duration}
              component={DurationField}
              disableClearable={!isCompactMode}
              validate={isCompactMode ? [validateDuration] : [validateRequired, validateDuration]}
            />
          </SmallFieldContainer>
        </FieldsContainer>
      </TopGroup>
      {!isCompactMode && (
        <RoomsWrapper>
          <FormFieldGroup>
            {allowBookCalendarRooms ? (
              <>
                {googleCalendarEnabled && (
                  <Fields
                    names={values(fieldNameMapping)}
                    label="Room(s)"
                    component={CalendarMultipleResourcesFields}
                    nameMapping={fieldNameMapping}
                    locationId={locationId}
                    idFieldName="timeslot_id"
                  />
                )}
                {allowSwitchCalendarOwner && (
                  <Fields
                    names={values(fieldNameMapping)}
                    component={CalendarManagerField}
                    nameMapping={fieldNameMapping}
                  />
                )}
              </>
            ) : (
              <Field name={fieldNameMapping.roomsInfo} label="Room(s)" component={TextField} />
            )}
          </FormFieldGroup>
        </RoomsWrapper>
      )}
      {isOnline && allowBookCalendarRooms ? (
        <WatchLinkWrapper>
          <div>
            <LinkProviderWrapper>
              <Field
                label="Virtual Conference Link"
                name={watchLinkMethodFieldName}
                component={SelectField}
                options={values(watchLinkOptions)}
                onChange={onChangeWatchLinkMethod}
              />
            </LinkProviderWrapper>
            {watchLinkMethodInfoText && (
              <InfoText top={4} bottom={16} content={watchLinkMethodInfoText} />
            )}
          </div>

          {isEditing &&
            initialWatchLink &&
            initialWatchLinkMethod === WATCH_LINK_METHOD.google &&
            watchLinkMethod === WATCH_LINK_METHOD.google && (
              <div>
                <InputLabel
                  required={!allowBookCalendarRooms && !isCompactMode}
                  htmlFor={fieldNameMapping.watch_link}
                >
                  URL
                </InputLabel>
                <LinkWrapper>
                  <Text maxWidth="40em" ellipsisOnOverflow={true} color={colors.neutral500}>
                    {watchLink}
                  </Text>
                  <Copy content={watchLink} />
                </LinkWrapper>
              </div>
            )}
          {watchLinkMethod && watchLinkMethod === WATCH_LINK_METHOD.custom && (
            <div>
              <Field
                name={fieldNameMapping.watch_link}
                required={!allowBookCalendarRooms && !isCompactMode}
                label="URL"
                placeholder="https://meet.google.com/aaa-aaa-aaa"
                component={TextField}
                validate={validateWatchLink()}
              />
            </div>
          )}
        </WatchLinkWrapper>
      ) : (
        isOnline && (
          <div>
            <LinkProviderWrapper>
              <Field
                label="Virtual Conference Link"
                name={fieldNameMapping.watch_link}
                placeholder="https://meet.google.com/aaa-aaa-aaa"
                component={TextField}
                validate={validateWatchLink()}
              />
            </LinkProviderWrapper>
            {watchLinkMethodInfoText && (
              <InfoText top={4} bottom={16} content={watchLinkMethodInfoText} />
            )}
          </div>
        )
      )}
      {!isCompactMode && (
        <ExtraInfoRowWrapper>
          <FormFieldGroup>
            <Field
              name={getFieldName('extra_info')}
              label="Extra Info"
              placeholder="If necessary, type instructions to guide attendees"
              component={TextField}
            />
          </FormFieldGroup>
          <DeleteTimeslotWrapper>
            <Button
              size="small"
              color="error"
              onClick={() => {
                onRemove(index);
              }}
            >
              Delete Timeslot
            </Button>
          </DeleteTimeslotWrapper>
        </ExtraInfoRowWrapper>
      )}
    </>
  );
  if (isCompactMode) return TimeslotForm;

  let startDate;
  let startTime;
  let durationInSeconds;
  let endTime;
  if (startsAt && duration) {
    startDate = moment.utc(startsAt).format('MMM DD YYYY');
    startTime = moment.utc(startsAt).format('hh:mm A');
    durationInSeconds = moment.utc(duration, 'hh:mm:ss').format('X');
    endTime = moment.utc(startsAt).add(durationInSeconds, 'seconds').format('hh:mm A');
  }

  const parsedroomsInfo = roomsInfo ? (isArray(roomsInfo) ? roomsInfo.join(',') : roomsInfo) : null;

  return (
    <TimeslotFieldWrapper>
      <TimeslotHeader isOpen={isOpen} onClick={() => setOpen(!isOpen)}>
        {isOpen ? (
          <>
            <Text size="h4" bold>
              Timeslot
            </Text>
            <Icon name="up" width={16} height={16} color={colors.neutral900} />
          </>
        ) : (
          <>
            <ClosedTimeslotPreviewWrapper>
              <TimeslotPreviewGroupWrapper>
                {startDate && (
                  <Text size="h4" medium>
                    {startDate}
                  </Text>
                )}
                {startTime && endTime && (
                  <Text size="h4" medium>
                    {startTime} - {endTime}
                  </Text>
                )}
              </TimeslotPreviewGroupWrapper>
              <TimeslotPreviewSeparator />
              <TimeslotPreviewRightGroup>
                <TimeslotPreviewGroupWrapper>
                  {parsedroomsInfo && (
                    <Text size="h4">
                      <EllipsisWrapper>{parsedroomsInfo}</EllipsisWrapper>
                    </Text>
                  )}
                  {watchLink && (
                    <Text size="h4">
                      <EllipsisWrapper>{watchLink}</EllipsisWrapper>
                    </Text>
                  )}
                </TimeslotPreviewGroupWrapper>
              </TimeslotPreviewRightGroup>
            </ClosedTimeslotPreviewWrapper>
            <Icon name="down" width={16} height={16} color={colors.neutral900} />
          </>
        )}
      </TimeslotHeader>
      {isOpen && TimeslotForm}
    </TimeslotFieldWrapper>
  );
};

NewTimeslotField.propTypes = {
  isCompactMode: PropTypes.bool,
  name: PropTypes.string,
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOnline: PropTypes.bool,
  onRemove: PropTypes.func,
  index: PropTypes.number,
  timeslot: PropTypes.object,
  formName: PropTypes.string,
  change: PropTypes.func,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  initialContentWatchLink: PropTypes.string,
  isEditing: PropTypes.bool,
};

NewTimeslotField.defaultProps = {
  timeslot: {},
};

export default NewTimeslotField;
