import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import GroupCard from '~/scenes/MyGroups/GroupCard';
import MyGroupsTopBar from '~/scenes/MyGroups/MyGroupsTopBar';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import { OldIcon } from '~/app/shared/components/Icon';
import MediaPoint from '~/app/shared/components/MediaPoint';
import { map } from 'lodash-es';

import { BaseGroupCard } from './styles';

export const GroupList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 4px 0 16px;
  width: 100%;

  ${MediaPoint.Tablet} {
    justify-content: flex-start;
  }
`;

const NewGroupLink = styled(BaseGroupCard)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${colors.emphasis600};
  border: 2px dashed ${colors.emphasis600};

  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: ${colors.emphasis600};
  }
`;

const NewGroupIcon = styled(OldIcon)`
  color: ${colors.emphasis600};
  line-height: 20px;
`;

const NewGroupText = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const MyGroups = ({ groups, groupsCount, updateSelectedFilters, filtersSelected }) => (
  <React.Fragment>
    <MyGroupsTopBar
      groupsCount={groupsCount}
      updateSelectedFilters={updateSelectedFilters}
      filtersSelected={filtersSelected}
    />
    <GroupList data-cy="my-groups">
      {map(groups, (group) => (
        <GroupCard key={group.id} group={group} />
      ))}
      <Link to={`${mapRoute('groupCreate')}?origin=${mapRoute('myGroups')}`}>
        <NewGroupLink>
          <NewGroupIcon name="account-group" size={28} />
          <NewGroupText>+ New Group</NewGroupText>
        </NewGroupLink>
      </Link>
    </GroupList>
  </React.Fragment>
);

MyGroups.propTypes = {
  groups: PropTypes.array,
  groupsCount: PropTypes.number,
  updateSelectedFilters: PropTypes.func,
  filtersSelected: PropTypes.object,
};

export default MyGroups;
