import { useComposableTrackCtx } from '~/features/tracks/contexts/ComposableTrackCtx';
import colors from '~/services/colors';
import { TrackItemCardList } from '~/app/tracks/components/TrackItemCardList';
import { Track, TrackLikeTypes } from '~/features/tracks/types';
import { Typography } from '~/common/components/Typography';
import { Box } from '@mui/material';

interface SectionlessTrackItemSummaryProps {
  track: Track;
}

export const SectionlessTrackItemSummary = (props: SectionlessTrackItemSummaryProps) => {
  const { track } = props;
  const { content_type, public_id, user_assignment } = track;
  const pageSize = 9;

  const { filterTrackItems, getSectionOrItemIndex } = useComposableTrackCtx();
  const trackItems = filterTrackItems(public_id);

  const trackItemsCount = track.denormalized_child_items_count ?? 0;
  const trackItemsText = `${trackItemsCount} ${trackItemsCount === 1 ? 'item' : 'items'}`;

  return (
    <Box marginY={2.5}>
      <Box marginBottom={2}>
        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>Summary</Typography>
        <Typography sx={{ fontSize: 14, color: colors.neutral500 }}>{trackItemsText}</Typography>
      </Box>
      <TrackItemCardList
        items={trackItems}
        getTrackItemIndex={getSectionOrItemIndex}
        pageSize={pageSize}
        isSummary
        trackType={content_type as TrackLikeTypes}
        isPreview={!user_assignment}
      />
    </Box>
  );
};
