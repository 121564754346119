import {
  Box,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  Stack,
  dialogClasses,
  dialogContentClasses,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { CloseOutlinedIcon } from '~/vendor/mui-icons';

type InsightsModalHeaderProps = {
  title: string;
  onClose: () => void;
};

function InsightsModalHeader(props: InsightsModalHeaderProps) {
  const { title, onClose } = props;

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        padding: '8px 52px',
        height: '44px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        component="h2"
        variant="h6"
        sx={{
          fontSize: '1rem',
          lineHeight: '1.75',
        }}
      >
        {title}
      </Typography>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: '12px',
        }}
      >
        <CloseOutlinedIcon />
      </IconButton>
    </Box>
  );
}

type InsightsModalContentProps = {
  children: React.ReactNode;
};

function InsightsModalContent(props: InsightsModalContentProps) {
  const { children } = props;

  return (
    <DialogContent
      dividers
      sx={{
        [`& .${dialogContentClasses.dividers}`]: {
          borderColor: grey[400],
        },
      }}
    >
      {children}
    </DialogContent>
  );
}

type InsightsModalFooterProps = {
  children: React.ReactNode;
};

function InsightsModalFooter(props: InsightsModalFooterProps) {
  const { children } = props;

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        padding: '12px 16px',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      {children}
    </Stack>
  );
}

type InsightsModalProps = {
  onClose: () => void;
  children: React.ReactNode;
};

function InsightsModal(props: InsightsModalProps) {
  const { onClose, children } = props;

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      sx={{
        [`& .${dialogClasses.paper}`]: {
          borderRadius: '8px',
        },
      }}
    >
      {children}
    </Dialog>
  );
}

export { InsightsModal, InsightsModalHeader, InsightsModalContent, InsightsModalFooter };
