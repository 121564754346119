import { get } from 'lodash-es';

import { CONTENT_TYPES } from '~/app/catalog/constants';
import { ContentItem } from '~/app/shared-content-item/interfaces';

import { TrackSupportedContentItemTypes } from '../types';

const supportedContentItemTypesMapping: { [v in TrackSupportedContentItemTypes]: v } = {
  [CONTENT_TYPES.eventtype]: CONTENT_TYPES.eventtype,
  [CONTENT_TYPES.event]: CONTENT_TYPES.event,
  [CONTENT_TYPES.article]: CONTENT_TYPES.article,
  [CONTENT_TYPES.linkedcontent]: CONTENT_TYPES.linkedcontent,
  [CONTENT_TYPES.assessment]: CONTENT_TYPES.assessment,
  [CONTENT_TYPES.track]: CONTENT_TYPES.track,
  [CONTENT_TYPES.video]: CONTENT_TYPES.video,
  [CONTENT_TYPES.codelab]: CONTENT_TYPES.codelab,
  [CONTENT_TYPES.course]: CONTENT_TYPES.course,
  [CONTENT_TYPES.task]: CONTENT_TYPES.task,
  [CONTENT_TYPES.multiple_choice_question]: CONTENT_TYPES.multiple_choice_question,
  [CONTENT_TYPES.text_question]: CONTENT_TYPES.text_question,
} as const;

export function getSupportedContentItemType(
  contentItem: ContentItem | undefined
): TrackSupportedContentItemTypes | undefined {
  if (!contentItem) {
    return;
  }

  const contentType = get(contentItem, 'content_type');
  if (!contentType) {
    return;
  }

  return supportedContentItemTypesMapping[contentType];
}
