import { DialogContentText, Button } from '@mui/material';
import {
  InsightsModal,
  InsightsModalHeader,
  InsightsModalContent,
  InsightsModalFooter,
} from '~/common/components/InsightsModal';
import { Track } from '../types';

type TrackInsightsModalProps = {
  track: Track;
  onClose: () => void;
};

export function TrackInsightsModal(props: TrackInsightsModalProps) {
  const { track, onClose } = props;

  return (
    <InsightsModal onClose={onClose}>
      <InsightsModalHeader title={`${track.name} Insights`} onClose={onClose} />

      <InsightsModalContent>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          {Array.from({ length: 50 })
            .map(
              () => `Cras mattis consectetur purus sit amet fermentum.
  Cras justo odio, dapibus ac facilisis in, egestas eget quam.
  Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
  Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`
            )
            .join('\n')}
        </DialogContentText>
      </InsightsModalContent>

      <InsightsModalFooter>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={onClose} variant="contained">
          View Enrollments
        </Button>
      </InsightsModalFooter>
    </InsightsModal>
  );
}
