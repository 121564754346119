import queryString from 'query-string';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import styled from 'styled-components';

import { toast } from '~/app/notifications/components/NotificationCenter';
import colors from '~/services/colors';
import { useEntityMetrics } from '~/services/metrics';
import AccessRestricted from '~/app/shared/components/AccessRestricted';
import MediaPoint from '~/app/shared/components/MediaPoint';
import { useCurrentUser, useOriginRoute } from '~/app/shared/hooks';

import { get } from 'lodash-es';
import { useQuery } from '@tanstack/react-query';
import { queries } from '~/queries';
import Loading from '~/app/shared/components/Loading';
import { useEditGroup } from '~/features/groups/api/mutations';

import GroupEditForm from './GroupEditForm';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 30px;
  ${MediaPoint.DesktopSm} {
    margin-top: 50px;
  }
`;

const Header = styled.div`
  align-self: center;
  width: 300px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  ${MediaPoint.Tablet} {
    width: 500px;
  }
  ${MediaPoint.DesktopSm} {
    width: 600px;
  }
`;

const HeaderTitle = styled.h2`
  color: ${colors.neutral400};
  justify-self: flex-start;
`;

const GroupEdit = () => {
  const { id } = useParams();
  const { trackEntityActivity } = useEntityMetrics();

  const currentUser = useCurrentUser();
  const { home_page_url_path: homePath } = currentUser;
  const redirectionRoute = useOriginRoute(homePath);
  const history = useHistory();
  const location = useLocation();

  const editGroupMutation = useEditGroup(id);

  const onSubmitHandler = async (values) => {
    const response = await editGroupMutation.mutateAsync(values);
    return response;
  };

  const { data: initialValues, isLoading, isError } = useQuery(queries.groups.detail(id, 'detail'));

  if (isError) {
    return (
      <Container>
        <Header>
          <AccessRestricted />
        </Header>
      </Container>
    );
  }

  if (isLoading) return <Loading />;

  const onSubmitSuccessHandler = (result) => {
    trackEntityActivity({
      id: result.id,
      isEdit: true,
      entityType: 'group',
    });

    toast.success(
      'Group updated successfully',
      `You updated group ${result.name}, a ${
        result.is_private ? 'private' : 'public'
      } group successfully.`
    );

    const args = queryString.parse(location.search);

    if (!get(args, 'origin')) {
      history.push(homePath);
      return;
    }
    history.push(args.origin);
  };

  return (
    <Container>
      <Header>
        <HeaderTitle>Edit {initialValues.name}</HeaderTitle>
        <GroupEditForm
          initialValues={initialValues}
          redirectionRoute={redirectionRoute}
          currentUser={currentUser}
          onSubmitSuccessHandler={onSubmitSuccessHandler}
          onSubmitHandler={onSubmitHandler}
        />
      </Header>
    </Container>
  );
};

export default GroupEdit;
