import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import structure from 'redux-form/lib/structure/plain';
import styled from 'styled-components';

import { mapRoute } from '~/services/requests';
import { generateGuid } from '~/services/utils';
import { get, forEach } from 'lodash-es';

const CalendarManagedBy = styled.div`
  display: inline-block;
  text-align: right;
  width: 100%;
`;

const inputGetter = (nameMapping, names, fields) => (key) => {
  const mapping = {};
  forEach(names, (n) => {
    mapping[n] = structure.getIn(fields, n);
  });

  return mapping[nameMapping[key]].input;
};

class CalendarManagerField extends React.Component {
  getFieldInput = (key) => {
    const { nameMapping, names, ...fields } = this.props;
    return inputGetter(nameMapping, names, fields)(key);
  };

  handleSwitchCalendar = () => {
    const { currentUser } = this.props;

    const idInput = this.getFieldInput('id');
    const calendarOwnerInput = this.getFieldInput('resourceOwner');
    const roomsInfoInput = this.getFieldInput('roomsInfo');
    const roomsInput = this.getFieldInput('rooms');

    const newId = generateGuid();
    idInput.onChange(newId);
    calendarOwnerInput.onChange(currentUser);
    roomsInfoInput.onChange('');
    roomsInput.onChange([]);
  };

  render = () => {
    const { currentUser } = this.props;
    const calendarOwnerInput = this.getFieldInput('resourceOwner');

    return (
      <CalendarManagedBy>
        Calendar Event managed by:&nbsp;
        {(() => {
          if (calendarOwnerInput.value.id === currentUser.id) {
            return 'You';
          }
          if (calendarOwnerInput.value) {
            return (
              <span>
                <a href={mapRoute('userProfile', { id: calendarOwnerInput.value.id })}>
                  {calendarOwnerInput.value.name}
                </a>
                {/* eslint-disable-next-line react/button-has-type */}
                <button className="btn btn-link" onClick={this.handleSwitchCalendar}>
                  (Switch to your Calendar)
                </button>
              </span>
            );
          }

          return (
            <span>
              PlusPlus
              {/* eslint-disable-next-line react/button-has-type */}
              <button className="btn btn-link" onClick={this.handleSwitchCalendar}>
                (Switch to your Calendar)
              </button>
            </span>
          );
        })()}
      </CalendarManagedBy>
    );
  };
}

CalendarManagerField.propTypes = {
  names: PropTypes.array,
  nameMapping: PropTypes.object,
  locationId: PropTypes.number,
  currentUser: PropTypes.object,
  idFieldName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  currentUser: get(state, 'user.currentUser'),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarManagerField);
