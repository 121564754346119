import { useMutation } from '@tanstack/react-query';

import { apiDelete, apiPatch, apiPost } from '~/services/requests';

export function useCreateGroup() {
  return useMutation({
    mutationFn: async (data: any) => {
      const { data: response } = await apiPost('internalgroups_api:list_create', {}, data);
      return response;
    },
  });
}

export function useEditGroup(id: number) {
  return useMutation({
    mutationFn: async (data: any) => {
      const { data: response } = await apiPatch(
        'internalgroups_api:retrieve_update_delete',
        id,
        data
      );
      return response;
    },
  });
}

export function useDeleteGroup() {
  return useMutation({
    mutationFn: async (id: number) => {
      const { data: response } = await apiDelete('internalgroups_api:retrieve_update_delete', id);
      return response;
    },
  });
}

export function useBulkDeleteGroups() {
  return useMutation({
    mutationFn: async (data: number[]) => {
      const { data: response } = await apiDelete('internalgroups_api:bulk_delete', {}, data);
      return response.data;
    },
  });
}
