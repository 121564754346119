import { CONTENT_TYPES } from '~/app/catalog/constants';
import { Track } from '~/features/tracks/types';
import { displayDatetime, formatDuration } from '~/services/datetime';
import { FullTrackSection, TrackItem } from '~/app/tracks/interfaces';
import { filter, isEmpty, isNil, map, size, get } from 'lodash-es';

export function getTrackProgress(track: Track): number {
  const assignment = track.user_assignment;

  if (isNil(assignment)) return 0;

  const progress = get(assignment, 'progress.progress', 0);
  return progress * 100;
}

export function getTrackSectionProgress(section: FullTrackSection) {
  const { items } = section;
  if (isEmpty(items)) return null;

  const itemsWithAssignments = filter(items, (item) => !isNil(item?.content_item?.assignment));
  const itemsWithCompletedAssignments = filter(
    itemsWithAssignments,
    (item) => !isNil(item?.content_item?.assignment?.completed_datetime)
  );

  return (size(itemsWithCompletedAssignments) / size(itemsWithAssignments)) * 100;
}

export function getTrackItemsWithHumanizedDate(trackItems: TrackItem[], currentUser): TrackItem[] {
  const { timezone: currentUserTimezone } = currentUser;

  const transformedTrackItems: TrackItem[] = map(trackItems, (item) => {
    const { content_item: contentItem } = item;

    if (contentItem.content_type !== CONTENT_TYPES.event) {
      return item;
    }

    const humanizedDate = `${displayDatetime(
      contentItem.utc_datetime,
      contentItem.timezone,
      currentUserTimezone,
      contentItem.is_online
    )} - ${formatDuration(contentItem.duration)}`;

    return {
      ...item,
      content_item: { ...contentItem, humanizedDate },
    };
  });

  return transformedTrackItems;
}

type TrackSectionsAndItemsCountTextArgs = {
  sectionsCount: number;
  itemsCount: number;
  separator?: string;
};

export function getTrackSectionsAndItemsCountText(args: TrackSectionsAndItemsCountTextArgs) {
  const { sectionsCount, itemsCount, separator = ', ' } = args;

  let sectionsCountText = '';
  if (sectionsCount > 0) {
    sectionsCountText = `${sectionsCount} section${sectionsCount > 1 ? 's' : ''}`;
  }

  const itemsCountText = `${itemsCount} item${itemsCount > 1 ? 's' : ''}`;

  if (sectionsCount === 0) {
    return itemsCountText;
  }

  return `${sectionsCountText}${separator}${itemsCountText}`;
}

interface TrackItemBorderStyle {
  borderStyle?: string;
  borderWidth?: string;
  padding?: number | string;
}

export const getTrackItemBorderStyle = (item: TrackItem): TrackItemBorderStyle => {
  const isRequired = get(item, 'is_required', true);
  return isRequired
    ? { borderStyle: 'solid', padding: '1px', borderWidth: '1px' }
    : {
        borderStyle: 'dashed',
        borderWidth: '2px',
        padding: '0px',
      };
};
