import { CONTENT_TYPES } from '~/app/catalog/constants';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { useToggles } from '~/app/shared/hooks';

/**
 * Checks if track insights can be displayed.
 *
 * @param contentItem - The content item to check.
 * @returns
 */
export function useCanDisplayTrackInsights(contentItem: ContentItem) {
  const { toggle_track_insights: toggleTrackInsights } = useToggles();

  const isTrack = contentItem.content_type === CONTENT_TYPES.track;

  return Boolean(toggleTrackInsights) && isTrack;
}
