import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { OldFilterPageHeader } from '~/app/navigation/components/OldFilterPage';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { mapRoute } from '~/services/requests';
import Button from '~/app/shared/components/Button';
import Icon from '~/app/shared/components/Icon';
import Loading from '~/app/shared/components/Loading';
import MediaPoint from '~/app/shared/components/MediaPoint';
import PageTitle from '~/app/shared/components/PageTitle';

import { includes } from 'lodash-es';

import MyGroups from './MyGroups';
import { GroupCardSizeWithMargins } from './styles';

import { useQuery } from '@tanstack/react-query';
import { queries } from '~/queries';
import Page404 from '~/scenes/404';
import { useCurrentUser } from '~/app/shared/hooks';
import { CREATE_GROUP_PERMISSION } from '~/app/shared/permissions';

export const Container = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${MediaPoint.Tablet} {
    width: calc((${GroupCardSizeWithMargins}) * 2);
  }

  ${MediaPoint.DesktopSm} {
    width: calc((${GroupCardSizeWithMargins}) * 3);
  }
`;

const MyGroupsContainer = () => {
  const currentUser = useCurrentUser();

  const [filtersSelected, setFiltersSelected] = useState({ visibility: ['owned'], o: 'name' });
  const { trackActivity } = useMetrics();

  useEffect(() => {
    trackActivity(METRICS_ACTIVITIES.MY_GROUPS_VIEW);
  }, [filtersSelected]);

  const {
    data: { results: groups = [], count: groupsCount = 0 } = {},
    isLoading,
    isError,
  } = useQuery(queries.groups.list(filtersSelected));

  if (isError) return <Page404 />;

  if (isLoading) return <Loading />;

  const updateSelectedFilters = (newFilters) => {
    setFiltersSelected({ ...filtersSelected, ...newFilters });
  };

  const canCreateGroup = includes(currentUser.permissions, CREATE_GROUP_PERMISSION);

  return (
    <React.Fragment>
      <PageTitle title="My Groups" />
      <OldFilterPageHeader
        title="My Groups"
        searchParam="q"
        searchPlaceholder="Find a group..."
        searchOnChange={updateSelectedFilters}
        actions={[
          <React.Fragment key="add-group-button">
            {canCreateGroup && (
              <Button
                startIcon={<Icon name="plus" />}
                size="small"
                route={`${mapRoute('groupCreate')}?origin=${mapRoute('myGroups')}`}
                data-cy="new-group-button"
              >
                New Group
              </Button>
            )}
          </React.Fragment>,
        ]}
      />
      <Container>
        <MyGroups
          groups={groups}
          groupsCount={groupsCount}
          filtersSelected={filtersSelected}
          updateSelectedFilters={updateSelectedFilters}
        />
      </Container>
    </React.Fragment>
  );
};

export default MyGroupsContainer;
